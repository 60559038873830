import axios from 'axios'
import endpoints from "./endpoints";

class SeListComApi {
    resource = `${endpoints.apiNew}/serp`;
    getSeAll () {
        return axios(`${this.resource}/getengines/`, {
            method: 'get',
            withCredentials: false,
        });
    }
    // axios(`${endpoints.api}/serp/se/info/`
    getSeById (se) {
        return axios(`${this.resource}/getseinfo`, {
            method: 'post',
            withCredentials: true,
            data: {
                se: se
            }
        });
    }

    getSeCount () {
        return axios(`${this.resource}/getcountengines/`, {
            method: 'get',
            withCredentials: false,
        });
    }
}

export default new SeListComApi();
