import {subDays, startOfWeek, subWeeks, differenceInCalendarDays, parseISO, subMonths, startOfMonth,
    startOfISOWeek, isMonday, getDaysInMonth, setDate, setDay, addDays, addMonths, isBefore, getDay,
    eachDayOfInterval, endOfWeek, isEqual, isAfter, addYears,  compareAsc, compareDesc, lastDayOfMonth} from 'date-fns';
/*
* yy, yyyy - year
* --------
* M (1, 2, ..., 12),
* MM (01, 02, ..., 12),
* MMM (Jan, Feb, ..., Dec),
* MMMM (January, February, ..., December) - month
* ------------
* d (1, 2, ..., 31),
* dd (01, 02, ..., 31) - day of month
* -----------
* h (1, 2, ..., 11, 12),
* hh (01, 02, ..., 11, 12) - Hour [1-12]
* H (0, 1, 2, ..., 23),
* HH (00, 01, 02, ..., 23) - Hour [0-23]
* ---------
* m (0, 1, ..., 59),
* mm (00, 01, ..., 59) - Minute
* ----------
* s (0, 1, ..., 59),
* ss (00, 01, ..., 59) - Second
* ---------
* a AM/PM
* */

const addLocalsObj = {
    'az': {
        monthValues: {
            narrow: ['Y', 'F', 'M', 'A', 'M', 'I', 'I', 'A', 'S', 'O', 'N', 'D'],
            abbreviated: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'İyun', 'İyul', 'Avq', 'Sen', 'Okt', 'Noy', 'Dek'],
            wide: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'İyun', 'İyul', 'Avqust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr']
        },
        dayValues: {
            narrow: ['B.', 'B.e', 'Ç.a', 'Ç.', 'C.a', 'C.', 'Ş.'],
            //abbreviated: ['Baz', 'Baz.e', 'Çər.a', 'Çər', 'Cüm.a', 'Cüm', 'Şə'],
            //wide: ['Bazar', 'Bazar ertəsi', 'Çərşənbə axşamı', 'Çərşənbə', 'Cümə axşamı', 'Cümə', 'Şənbə']
        },
    },
    'bs': {
        monthValues: {
            narrow: ['1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', '9.', '10.', '11.', '12.'],
            abbreviated: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'okt', 'nov', 'dec'],
            wide: ['januar', 'februar', 'mart', 'april', 'maj', 'juni', 'juli', 'avgust', 'septembar', 'oktobar', 'novembar', 'decembar']
        },
        dayValues: {
            narrow: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
            //abbreviated: ['ned', 'pon', 'uto', 'sre', 'čet', 'pet', 'sub'],
            //wide: ['nedjelja', 'ponedjeljak', 'utorak', 'srijeda', 'četvrtak', 'petak', 'subota']
        },
    },
    'ba': {
        monthValues: {
            narrow: ['1.', '2.', '3.', '4.', '5.', '6.', '7.', '8.', '9.', '10.', '11.', '12.'],
            abbreviated: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'avg', 'sep', 'okt', 'nov', 'dec'],
            wide: ['januar', 'februar', 'mart', 'april', 'maj', 'juni', 'juli', 'avgust', 'septembar', 'oktobar', 'novembar', 'decembar']
        },
        dayValues: {
            narrow: ['N', 'P', 'U', 'S', 'Č', 'P', 'S'],
            //abbreviated: ['ned', 'pon', 'uto', 'sre', 'čet', 'pet', 'sub'],
            //wide: ['nedjelja', 'ponedjeljak', 'utorak', 'srijeda', 'četvrtak', 'petak', 'subota']
        },
    },
    'mk': {
        monthValues: {
            abbreviated: ['јан', 'фев', 'мар', 'апр', 'мај', 'јун', 'јул', 'авг', 'септ', 'окт', 'ноем', 'дек'],
            wide: ['јануари', 'февруари', 'март', 'април', 'мај', 'јуни', 'јули', 'август', 'септември', 'октомври', 'ноември', 'декември']
        },
        dayValues: {
            narrow: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'sq': {
        monthValues: {
            narrow: ['J', 'S', 'M', 'P', 'M', 'Q', 'K', 'G', 'S', 'T', 'N', 'D'],
            abbreviated: ['Jan', 'Shk', 'Mar', 'Pri', 'Maj', 'Qer', 'Kor', 'Gus', 'Sht', 'Tet', 'Nën', 'Dhj'],
            wide: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor']
        },
        dayValues: {
            narrow: ['D', 'H', 'M', 'M', 'E', 'P', 'S'],
            //short: ['Di', 'Hë', 'Ma', 'Më', 'En', 'Pr', 'Sh'],
            //abbreviated: ['Die', 'Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht'],
            //wide: ['Dielë', 'Hënë', 'Martë', 'Mërkurë', 'Enjte', 'Premte', 'Shtunë']
        }
    },
    'al': {
        monthValues: {
            narrow: ['J', 'S', 'M', 'P', 'M', 'Q', 'K', 'G', 'S', 'T', 'N', 'D'],
            abbreviated: ['Jan', 'Shk', 'Mar', 'Pri', 'Maj', 'Qer', 'Kor', 'Gus', 'Sht', 'Tet', 'Nën', 'Dhj'],
            wide: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor']
        },
        dayValues: {
            narrow: ['D', 'H', 'M', 'M', 'E', 'P', 'S'],
            //short: ['Di', 'Hë', 'Ma', 'Më', 'En', 'Pr', 'Sh'],
            //abbreviated: ['Die', 'Hën', 'Mar', 'Mër', 'Enj', 'Pre', 'Sht'],
            //wide: ['Dielë', 'Hënë', 'Martë', 'Mërkurë', 'Enjte', 'Premte', 'Shtunë']
        }
    },
    'hy': {
        monthValues: {
            narrow: ['Հ', 'Փ', 'Մ', 'Ա', 'Մ', 'Հ', 'Հ', 'Օ', 'Ս', 'Հ', 'Ն', 'Դ'],
            abbreviated: ['հուն', 'փետ', 'մար', 'ապր', 'մայ', 'հուն', 'հուլ', 'օգս', 'սեպ', 'հոկ', 'նոյ', 'դեկ'],
            wide: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր']
        },
        dayValues: {
            narrow: ['Կ', 'Ե', 'Ե', 'Չ', 'Հ', 'Ո', 'Շ'],
            //abbreviated: ['կիր', 'երկ', 'երք', 'չոր', 'հնգ', 'ուրբ', 'շաբ'],
            //wide: ['կիրակի', 'երկուշաբթի', 'երեքշաբթի', 'չորեքշաբթի', 'հինգշաբթի', 'ուրբաթ', 'շաբաթ']
        }
    },
    'am': {
        monthValues: {
            narrow: ['Հ', 'Փ', 'Մ', 'Ա', 'Մ', 'Հ', 'Հ', 'Օ', 'Ս', 'Հ', 'Ն', 'Դ'],
            abbreviated: ['հուն', 'փետ', 'մար', 'ապր', 'մայ', 'հուն', 'հուլ', 'օգս', 'սեպ', 'հոկ', 'նոյ', 'դեկ'],
            wide: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր']
        },
        dayValues: {
            narrow: ['Կ', 'Ե', 'Ե', 'Չ', 'Հ', 'Ո', 'Շ'],
            //abbreviated: ['կիր', 'երկ', 'երք', 'չոր', 'հնգ', 'ուրբ', 'շաբ'],
            //wide: ['կիրակի', 'երկուշաբթի', 'երեքշաբթի', 'չորեքշաբթի', 'հինգշաբթի', 'ուրբաթ', 'շաբաթ']
        }
    },
    'is': {
        monthValues: {
            narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'Á', 'S', 'Ó', 'N', 'D'],
            abbreviated: ['jan.', 'feb.', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'sept.', 'okt.', 'nóv.', 'des.'],
            wide: ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember']
        },
        dayValues: {
            narrow: ['S', 'M', 'Þ', 'M', 'F', 'F', 'L'],
            //short: ['Su', 'Má', 'Þr', 'Mi', 'Fi', 'Fö', 'La'],
            //abbreviated: ['sun.', 'mán.', 'þri.', 'mið.', 'fim.', 'fös.', 'lau'],
            //wide: ['sunnudagur', 'mánudagur', 'þriðjudagur', 'miðvikudagur', 'fimmtudagur', 'föstudagur', 'laugardagur']
        }
    },
    'ka': {
        monthValues: {
            narrow: ['ია', 'თე', 'მა', 'აპ', 'მს', 'ვნ', 'ვლ', 'აგ', 'სე', 'ოქ', 'ნო', 'დე'],
            abbreviated: ['იან', 'თებ', 'მარ', 'აპრ', 'მაი', 'ივნ', 'ივლ', 'აგვ', 'სექ', 'ოქტ', 'ნოე', 'დეკ'],
            wide: ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი']
        },
        dayValues: {
            narrow: ['კვ', 'ორ', 'სა', 'ოთ', 'ხუ', 'პა', 'შა'],
            //short: ['კვი', 'ორშ', 'სამ', 'ოთხ', 'ხუთ', 'პარ', 'შაბ'],
            //abbreviated: ['კვი', 'ორშ', 'სამ', 'ოთხ', 'ხუთ', 'პარ', 'შაბ'],
            //wide: ['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი']
        }
    },
    'ge': {
        monthValues: {
            narrow: ['ია', 'თე', 'მა', 'აპ', 'მს', 'ვნ', 'ვლ', 'აგ', 'სე', 'ოქ', 'ნო', 'დე'],
            abbreviated: ['იან', 'თებ', 'მარ', 'აპრ', 'მაი', 'ივნ', 'ივლ', 'აგვ', 'სექ', 'ოქტ', 'ნოე', 'დეკ'],
            wide: ['იანვარი', 'თებერვალი', 'მარტი', 'აპრილი', 'მაისი', 'ივნისი', 'ივლისი', 'აგვისტო', 'სექტემბერი', 'ოქტომბერი', 'ნოემბერი', 'დეკემბერი']
        },
        dayValues: {
            narrow: ['კვ', 'ორ', 'სა', 'ოთ', 'ხუ', 'პა', 'შა'],
            //short: ['კვი', 'ორშ', 'სამ', 'ოთხ', 'ხუთ', 'პარ', 'შაბ'],
            //abbreviated: ['კვი', 'ორშ', 'სამ', 'ოთხ', 'ხუთ', 'პარ', 'შაბ'],
            //wide: ['კვირა', 'ორშაბათი', 'სამშაბათი', 'ოთხშაბათი', 'ხუთშაბათი', 'პარასკევი', 'შაბათი']
        }
    },
    'uk': {
        monthValues: {
            abbreviated: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
            wide: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень']
        },
        dayValues: {
            narrow: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'ua': {
        monthValues: {
            abbreviated: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
            wide: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень']
        },
        dayValues: {
            narrow: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'ru': {
        monthValues: {
            abbreviated: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
            wide: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь']
        },
        dayValues: {
            narrow: ['Н', 'П', 'В', 'С', 'Ч', 'П', 'С'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'lt': {
        monthValues: {
            abbreviated: ['sau', 'vas', 'kov', 'bal', 'geg', 'bir', 'lie', 'rugp', 'rugs', 'spa', 'lap', 'gru'],
            wide: ['sausis', 'vasaris', 'kovas', 'balandis', 'gegužė', 'birželis', 'liepa', 'rugpjūtis', 'rugsėjis', 'spalis', 'lapkritis', 'gruodis']
        },
        dayValues: {
            narrow: ['S', 'P', 'A', 'T', 'K', 'P', 'Š'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'gr': {
        monthValues: {
            wide: ['Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος'],
            abbreviated: ['іαν', 'фεβ', 'мάρ', 'aπρ', 'мάι', 'iού', 'iού', 'aύγ', 'Σεπ', 'oκτ', 'nοέ', 'Δεκ']
        },
        dayValues: {
            narrow: ['Δ', 'T', 'T', 'П', 'П', 'Σ', 'К'],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
    'zh': {
        monthValues: {
            wide: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', ' 八月', ' 九月', '十月', '十一月', '十二月'],
            abbreviated: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', ' 八月', ' 九月', '十月', '十一月', '十二月']
        },
        dayValues: {
            narrow: ['日','一','二', '三', '四', '五', '六',],
            //abbreviated: ['нед', 'пон', 'вто', 'сре', 'чет', 'пет', 'саб'],
            //wide: ['недела', 'понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота']
        }
    },
};
const dayMs = 86400000;

function shortYear(fullYear) {
    return fullYear%100;
}
function format(dateVal, type, options) {


    let date = dateVal;
    if(!date) {
        //console.error('Invalid date!');
        return;
    } else if(Object.prototype.toString.call(date) !== "[object Date]") {
        try {
            date = new Date(date);
        } catch (e) {
            //console.error('Invalid date!');
            return;
        }
    }

    if(typeof type !== 'string') {
        //console.error('Second param must be a string!');
        return date.toLocaleString();
    }

    const dateObj = options && options.utc ? {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth(),
        date: date.getUTCDate(),
        hours: date.getUTCHours(),
        minutes: date.getUTCMinutes(),
        seconds: date.getUTCSeconds(),
    } : {
        year: date.getFullYear(),
        month: date.getMonth(),
        date: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    };
    let formDate = type;
    let aVal;

    if(formDate.includes("MMM dd") && options && ( options.locale != 'en' && options.locale != 'zh')){
        //formDate.replace("MMM dd","dd MMM");
        formDate = formDate.replace("MMM dd","dd MMM");
    } else if(formDate.includes("MMM d") && options && ( options.locale != 'en' && options.locale != 'zh')){
        //formDate.replace("MMM dd","dd MMM");
        formDate = formDate.replace("MMM d","d MMM");
    } else if(formDate.includes("yyyy") && options && ( options.locale == 'zh')){
        //formDate.replace("MMM dd","dd MMM");
        formDate = 'yyyy,MMM dd';
    }

    if(type.includes('a')) {
        formDate = formDate.replace(/([a]+)/g, function(match, contents) {
            //aVal = date.getHours();
            //aVal = aVal < 12 ? 'AM' : 'PM';
            aVal = dateObj.hours < 12 ? 'AM' : 'PM';
            return '@a@';
        });
    }
    if(type.includes('y')) {
        // formDate = formDate.replace(/([y]+)/g, function(match, contents) {
        //   return contents.length > 2 ? date.getFullYear() : shortYear();//date.getYear();
        // });
        formDate = formDate.replace(/([y]+)/g, function(match, contents) {
            return contents.length > 2 ? dateObj.year : shortYear(dateObj.year);//date.getYear();
        });
    }
    if(type.includes('d')) {
        formDate = formDate.replace(/([d]+)/g, function(match, contents) {
            const dCount = dateObj.date; //date.getDate();
            return contents.length > 1 && dCount < 10 ? '0' + dCount : dCount;
        });
    }
    if(type.includes('h')) {
        formDate = formDate.replace(/([h]+)/g, function(match, contents) {
            //let hCount = (date.getHours()%12);
            let hCount = (dateObj.hours%12);
            if(hCount === 0) {
                hCount = 12;
            }
            return contents.length > 1 && hCount < 10 ? '0' + hCount : hCount;
        });
    }
    if(type.includes('H')) {
        formDate = formDate.replace(/([H]+)/g, function(match, contents) {
            //const hhCount = date.getHours();
            const hhCount = dateObj.hours;
            return contents.length > 1 && hhCount < 10 ? '0' + hhCount : hhCount;
        });
    }
    if(type.includes('m')) {
        formDate = formDate.replace(/([m]+)/g, function(match, contents) {
            //const minCount = date.getMinutes();
            const minCount = dateObj.minutes;
            return contents.length > 1 && minCount < 10 ? '0' + minCount : minCount;
        });
    }
    if(type.includes('s')) {
        formDate = formDate.replace(/([s]+)/g, function(match, contents) {
            //const sCount = date.getSeconds();
            const sCount = dateObj.seconds;
            return contents.length > 1 && sCount < 10 ? '0' + sCount: sCount;
        });
    }
    if(type.includes('M')) {
        formDate = formDate.replace(/([M]+)/g, function(match, contents) {
            //let mCount = date.getMonth();
            let mCount = dateObj.month;
            if(contents.length > 2) {
                if(options && options.locale && addLocalsObj[options.locale]) {
                    return contents.length === 3 ? addLocalsObj[options.locale].monthValues.abbreviated[mCount] : addLocalsObj[options.locale].monthValues.wide[mCount]
                }
                //for Intl.DateTimeFormat need locals
                mCount = options ? [options.locale, 'en'] : 'en'
            } else {
                mCount = mCount + 1;
            }

            switch (contents.length) {
                case 1: return mCount;
                case 2: return mCount < 10 ? '0' + mCount : mCount;
                case 3: return new Intl.DateTimeFormat(mCount, {month: 'short'}).format(date);
                default: return new Intl.DateTimeFormat(mCount, {month: 'long'}).format(date);
            }
        });
    }
    if(aVal) {
        formDate = formDate.replace(/@a@/g, aVal);
    }

    return formDate;
}
function intervalToDuration(endDateVal, startDateVal, options) {
    let endDate, startDate;
    if(!endDateVal || !startDateVal) {
        //console.error('Invalid date!');
        return;
    } else if(Object.prototype.toString.call(endDateVal) !== "[object Date]" || Object.prototype.toString.call(startDateVal) !== "[object Date]") {
        try {
            endDate = new Date(endDateVal);
            startDate = new Date(startDateVal);
        } catch (e) {
            //console.error('Invalid date!');
            return;
        }
        //const locale = options && options.locale ? options.locale : 'en';
        const mD = endDate - startDate;
        const dayD = Math.trunc(mD/dayMs);
        const yearD = Math.trunc(dayD/365);
        // const rtf = new Intl.RelativeTimeFormat([locale, 'en'], {
        //   localeMatcher: "lookup", // other values: "lookup"
        //   numeric: "always", // other values: "auto"
        //   style: "long", // other values: "short" or "narrow"
        // })
        // const textY = rtf.formatToParts(yearD, 'year');
        // const textD = rtf.formatToParts(dayD - (yearD * 365), 'day');
        return {
            years: yearD,
            days: dayD - (yearD * 365),
            alldays: dayD
        }
    }
};

function validateRange(range){

    let datesArr = range.split('_');
    if(datesArr && datesArr.length <2) { return false;}

    if (isNaN(new Date(datesArr[0])) || isNaN(new Date(datesArr[1]))) {
        return false;
    }
    let dateStart = format(new Date(datesArr[0]), 'yyyy-MM-dd');
    let dateEnd =  format(new Date(datesArr[1]), 'yyyy-MM-dd');
    let today = format(new Date(), 'yyyy-MM-dd');

    if(new Date(dateStart) <= new Date(dateEnd) &&  new Date(dateEnd) <= new Date(today)){
        return true;
    } else {
        return false;
    }

}


export {
    validateRange,
    format,
    addLocalsObj,
    subDays, startOfWeek, subWeeks, differenceInCalendarDays, parseISO,
    subMonths, startOfMonth, startOfISOWeek, isMonday, getDaysInMonth,
    setDate, setDay, addDays, addMonths, isBefore, getDay, eachDayOfInterval, endOfWeek,
    isEqual, isAfter, addYears, compareAsc, compareDesc, lastDayOfMonth, intervalToDuration
}
