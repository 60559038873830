<template>
    <div class="report-popup" v-if="show && reports">
        <div class="report-popup__header">
            <div class="report-popup__title" data-vl="12127">{{ll.i12127||'Progress'}}</div>
            <div class="report-popup__buttons">
                <span class="report-popup__counter">{{ num }}/{{ reports.length }}</span>
                <button class="report-popup__button mr-10" @click="small=!small"><img :class="[small?'small':'']" src="/mse/img/reports/arrow-white-down.svg" alt=""></button>
                <button class="report-popup__button" @click="close"><img src="/mse/img/reports/close-icon-white.svg" alt="Close"></button>
            </div>
        </div>
        <div class="report-popup__main" v-if="!small">
            <template v-for="(row, index) in reports">
                <div :key="index" v-if="row.st==0||row.st==2||row.st==1" class="report-popup__item"
						:class="[row.st==1?'item--success':'']" :data-id="row.id" :data-type="row.type">
                    <button v-if="row.st==1" class="btn-close" @click="closeOne(row)"><img src="/mse/img/reports/close-icon.svg" alt="Close"> </button>
                    <div class="report-popup__item-text" >
                        <h3 class="item-title">{{ll.i12128||'Report:'}}
                            <span v-if="row.type=='as'">{{ll.i12132}}</span>
                            <span v-else-if="row.type=='fs'">{{ll.i12133}}</span>
							<span v-else-if="row.type=='bp'">{{ll.i12209}}</span>
							<span v-else-if="row.type=='bplist'">{{ll.i12578}}</span>
							<span v-else-if="row.type=='co'">{{ll.i12210}}</span>
							<span v-else-if="row.type=='wa'">{{ll.i12213}}</span>
							<span v-else-if="row.type=='ps'">{{ll.i12212}}</span>
							<span v-else-if="row.type=='ch'">{{ll.i12214}}</span>
                            <span v-else>{{ll.i12131}}</span>
                            <span class="upper"> ({{ row.format }})</span>
                        </h3>
                        <p>{{ row.filename }}</p>
                        <h4 v-if="row.send" class="message" data-vl="12197">{{ll.i12197}}</h4>
                    </div>

                    <!--<button v-if="row.st==1" @click="downloadReport(row.link)"  class="btn-download">
                        <img class="icon-download" src="/mse/img/reports/download--icon.svg" data-vl="12129">{{ll.i12129||'Download'}}
                    </button> && reportsCach[row.id]==100-->
					<template v-if="row.st==1">
						<a v-if="row.type && ['bp','bplist','co','wa','ps','ch'].includes(row.type)" :href="row.link" download="" class="btn-download">
							<img class="icon-download" src="/mse/img/reports/download--icon.svg" data-vl="12129">{{ll.i12129||'Download'}}
						</a>
						<a v-else-if="row.link && row.link.substring(0, 4)=='http'" :href="row.link" download="" class="btn-download">
							<img class="icon-download" src="/mse/img/reports/download--icon.svg" data-vl="12129">{{ll.i12129||'Download'}}
						</a>
						<a v-else :href="'/api/download/report'+row.link" download="" class="btn-download">
							<img class="icon-download" src="/mse/img/reports/download--icon.svg" data-vl="12129">{{ll.i12129||'Download'}}
						</a>
					</template>
                    <div v-else class="progress-loader">
                        <div class="progress-loader__line" :style="{ width:progressFunc(row.progress)+'%'}"></div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
export default {
    name: "ReportPopupDownload",
    components: {

    },
    computed:{
        ll() {
            return this.$root.ll;
        },
    },
    data: function(){
        return {
            show: false,
            small: false,
            trigger:null,
            reports: null,

            num:0,
            total:1,
            progress: 46,

            report:{
                id:null,
                name: null,
                type: null,
                filename:null,
                file:null,
                start:false
            },
            reportsCach:[]
        }
    },
	emits: ['update:modelValue'],
    methods: {
        async init(){
            if(this.show){
                await this.statusDownload();
                if(this.reports && this.num < this.reports.length){
                    this.trigger = setInterval(async()=> {
                        await this.statusDownload();
                    },4000);
                }
                //this.$emit('input', {start:false});
				this.$emit('update:modelValue', {start:false});

            }
            //this.report.name = this.ll.i12130||'Keywords in TOP';
            //this.report.type = 'PDF';
            //this.report.filename = 'Keywords_alta.ge_google.com_All_2022-12-12_en.pdf';
        },
        async statusDownload(){
            let res = await axios.post('/api/reports/download_status', {idrg:this.modelValue.idrg});
            if (res && res.data && res.data.result){
                this.reports = res.data.result;
                this.num=0;
                for(let jj in this.reports){
                    if(this.reports[jj].st==1) this.num++;
                    if(this.reports[jj].idr==0 && this.reports[jj].idrbs>0 && this.reports[jj].pr==100) this.reports[jj].send = 1;

                    if(this.reportsCach[this.reports[jj].id] && this.reports[jj].pr<100){
                        if( this.reportsCach[this.reports[jj].id] >= this.reports[jj].pr ) {
                            if (Math.abs(this.reportsCach[this.reports[jj].id] - this.reports[jj].pr) < 9) this.reports[jj].progress = this.reportsCach[this.reports[jj].id] = this.reportsCach[this.reports[jj].id] + 2;
                            else this.reports[jj].progress = this.reportsCach[this.reports[jj].id];
                        }
                        else this.reports[jj].progress = this.reportsCach[this.reports[jj].id] = this.reports[jj].pr;
                        //console.log(333, this.reportsCach[this.reports[jj].id]);
                    }
                    else {
                        if(this.reports[jj].pr < 100) this.reports[jj].progress = this.reportsCach[this.reports[jj].id] = 1;
                        else if(this.reports[jj].st == 1){
                            this.reports[jj].progress = 100;
                            this.reportsCach[this.reports[jj].id] = 100;
                        }
                        //console.log(444, this.reportsCach[this.reports[jj].id]);
                    }
                }
                //console.log(111, this.reports.length, this.num);
                if(this.reports.length==this.num){
                    clearInterval(this.trigger);
                }
            }
            else {
                this.show = false;
                clearInterval(this.trigger);
            }
        },
        close(){
            this.show=false;
            //this.$emit('input', {start:false});
			this.$emit('update:modelValue', {start:false});
            clearInterval(this.trigger);
        },
        progressFunc(val){
            if(val<1) val = 1;
            else if(val>100) val=100;
            return val;
        },
        checkReport(){
            let result = false;
            for(let jj in this.reports) if(reports[jj].st==0) result = true;
            return result;
        },
        async downloadReport(link){
            let res = await axios.post('/api/download/report'+link);
            if (res && res.data && res.data.result){

                //window.location.href = 'https://semalt.com/pdf'+res.data.result;
                return;
            }
        },
        async closeOne(row){
            let res = await axios.post('/api/reports/close_report', {idrg:row.id});
            if (res && res.data && res.data.result){
                row.st=3;
                await this.statusDownload();
            }
        }
    },
    props: {
        idrg: Number,
        start: Boolean,
		modelValue: Object
    },
	provide() {
		return {
			report_progress_close: this.close,
		}
	},
    mounted(){
        this.init();
    },
    watch:{
        /*'$attrs.value.start'(to){
            console.log('Start', to);
            if(to){
                this.show = true;
                this.init();
            }
        }*/
		'modelValue.start'(to){
			//console.log('Start', to);
			if(to){
				this.show = true;
				this.init();
			}
		}
    }
}
</script>

<style src="../../../public/css/report-popup-download.css" lang="css" scoped></style>