import { createApp} from 'vue';
import App from './App.vue'
import router from './router'
import {i18n} from "./utils/i18nNew";
import VueLazyload from 'vue-lazyload';

import {store} from './store/storeNew';

const app = createApp(App)

app.use(router);
app.use(i18n);
app.use(store);
app.use(VueLazyload, {
    preLoad: 1.3,

    attempt: 1,
    // the default is ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend']
    listenEvents: [ 'scroll' ],
    lazyComponent: true,
    adapter: {
        loaded ({ el,naturalHeight, naturalWidth}) {
            // do something here
            el.setAttribute('width',naturalWidth);
            el.setAttribute('height',naturalHeight);
        }
    },
    // set observer to true
    observer: true,

    // optional
    observerOptions: {
        rootMargin: '0px',
        threshold: 0.1
    }

})


const root = app.mount('#app21');

//import './assets/main.css'
import './assets/js/semalt.func.js'


Date.prototype.fdate = function() {
    var mm = this.getMonth() + 1;
    var dd = this.getDate();

    return [this.getFullYear(), '-',
        (mm>9 ? '' : '0') + mm, '-',
        (dd>9 ? '' : '0') + dd
    ].join('');
};
Date.prototype.getdaydiff = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
/*
app.directive('click-outside', {
  mounted(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (vnode.instance && typeof vnode.instance[binding.value] === 'function') {
          vnode.instance[binding.value](event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
 */
export {app, root,i18n}