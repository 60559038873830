import { createStore } from 'vuex';
import storage from '../store/storage';
//import sidebarStore from '~/components/kernel/Sidebar/store';
//import appStore from '../store/appNew';
import seListStore from '../store/seListStore';
import projectsListStore from '../store/projectsListStore';


export const store = createStore({
    modules: {
        'mainStore': storage,
        'seStore': seListStore,
        'projectStore': projectsListStore
    }
});
