import axios from 'axios'
import endpoints from "./endpoints";

class ProjectsListComApi {
    resource = `${endpoints.api}/sites`;

    get(params, url) {

        return axios('https://semalt.com/api/serp/get_sites', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    add(sitesAll, sites) {
        return axios('https://semalt.com/api/serp/add_site', {
            method: 'post',
            data: {
                sitesAll: sites.length > 0 ? [] : sitesAll,
                page: sites.length > 0 ? sites[0].page : '',
                se: sites.length > 0 ? sites[0].se : ''
            },
            withCredentials: true
        })
    }

    move(params) {
        return axios('https://semalt.com/api/serp/move_site', {
            method: 'get',
            params: params,
            withCredentials: true
        })
    }

    delete(site) {
        return axios('https://semalt.com/api/serp/delete_site', {
            method: 'post',
            data: {
                site: site
            },
            withCredentials: true
        })
    }

    getByDomain(site) {

        return axios(`${this.resource}/url-info/`, {
            method: 'get',
            params: {
                site: site
            },
            withCredentials: true
        })
    }

    async getProjectInfo(ids) {
        return axios('/api/project/get_info_site',{
            method:'post',
            data:{
                ids:ids
            },
            withCredentials: true
        });


    }

    getProjectArchived(params) {

        return axios('/api/projectmore/get_archived_seo_results', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    getRankingsToDate(params) {

        return axios({
            method: 'post',
            url:'https://semalt.com/api/projectmore/get_rankings_to_date',
            data: params,
            withCredentials: true
        })
    }

    getErrorToFix(params) {

        return axios('https://semalt.com/api/projectmore/error_to_fix', {
            method: 'get',
            params: params,
            withCredentials: true
        })
    }

    getProjectWesiteAnalyzer(params) {

        return axios('https://semalt.com/api/project/project_wa', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }


    updateThisProject(params) {

        return axios('https://semalt.com/handlers/update_this_project.php', {
            method: 'post',
            params: params,
            withCredentials: true
        })
    }

    updateSpecificProject(params) {

        return axios('https://semalt.com/handlers/update_specific_projects.php', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    updateWorkPos(params) {
        return axios({
            method: 'post',
            url: `https://semalt.com/api/project/update_pos`,
            data: params,
            withCredentials: true
        })
    }

    addRobotTasks(params) {
        return axios('https://semalt.com/robot/robot_tasks.php', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    getUpdateStatus(params) {
        return axios({
            url: 'https://semalt.com/api/update/get_update_status',
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    isSeoTrial(params) {
        return axios('/api/user/is_autoseo_trial', {
            method: 'get',
            params: params,
            withCredentials: true
        })
    }

    getPageProject(params) {
        return axios('/api/project/get_page_project', {
            method: 'post',
            data: params,
            withCredentials: true
        })
    }

    getPromotionHistory(params) {
        return axios('/api/projectmore/get_promotion_history', {
            method: 'get',
            params: params,
            withCredentials: true
        })
    }



}


export default new ProjectsListComApi()
