/* eslint-disable */
window.initializeMaps = function(){
    var myLatlng3 = new google.maps.LatLng(50.439696, 30.512623 ); // 50.427140, 30.508862
    var mapOptions3 = {
        zoom: 13, //15,
        center: myLatlng3,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false
    }
    var image = '//semalt.com/img/contacts/semalt-ico2.png';
    var map3 = new google.maps.Map(document.getElementById('googleMaps'), mapOptions3);
    var contentString3 = '<div class="cont">'+
        '<b>Head Office</b>\n' +
        '<a href="//semalt.com">www.semalt.com</a>\n' +
        'Pavla Skoropadskoho str., 9A,\n' +
        'Kyiv, Ukraine.'+
        '</div>';
    var infowindow3 = new google.maps.InfoWindow({
        content: contentString3
    });
    var marker3 = new google.maps.Marker({
        position: myLatlng3,
        map: map3,
        title: 'Semalt',
        icon: image
    });
    google.maps.event.addListener(marker3, 'click', function() {
        infowindow3.open(map3,marker3);
    });
}
