<template>
	<div :class="[$root.lang ? 'lang-'+$root.lang:'']" v-if="ll"> <!-- v-if="$root.ll"-->
		<meta name="viewport" content="width=device-width, initial-scale=1">
		<router-view ref="header" name="header"></router-view>
		<router-view ref="rView"></router-view>
		<router-view ref="footer" name="footer"></router-view>

		<auth-user v-if="load.refAuth" ref="refAuth"></auth-user>
		<block-manager v-if="load.refBlockManager" ref="refBlockManager"></block-manager>
		<web-analyze v-if="load.refWebAnalyze" ref="refWebAnalyze"></web-analyze>

		<div class="overlay" :class="[overlayShow?'is-visible':'']" id="overlay"></div>
		<alert ref="alert"></alert>
		<div v-if="$root.user.load && googleAutoAuth" style="display: none;">
				<div id="g_id_onload"
						 data-client_id="79585785726-985bp729aclbskbe13qmmadc0ievr65e.apps.googleusercontent.com"
						 data-login_uri="https://semalt.com/api/user/auth_google?sit=11"
						 data-skip_prompt_cookie="gr_session2">
				</div>
				<div class="g_id_signin"
						 data-type="standard"
						 data-size="large"
						 data-theme="outline"
						 data-text="sign_in_with"
						 data-shape="rectangular"
						 data-logo_alignment="left">
				</div>
		</div>
		<popup ref="popup" :obj="appPopup" :confirmFunc="funcPopup"></popup>
		<report-popup-download v-model="popupDownload"></report-popup-download>
		<!-- 20240724 <button-marketing v-if="buttonMarketingShow && !mobileVer"></button-marketing> -->
		<chat v-if="load.refChat"></chat>
		<SiteGuide page-name="rankings"/>
	</div>

</template>

<script>
/* eslint-disable */
//2023 import AppNet from "./semalt-net/AppNet.vue";
//import LazyHydrate from 'vue-lazy-hydration';
import { defineAsyncComponent, ref, onMounted  } from 'vue'

const AuthUser =  defineAsyncComponent(() => import( './components/Auth'));
import BlockManager from './components/BlockManager';
const WebAnalyze = defineAsyncComponent(() => import( './components/WebAnalyze') );
const Alert = defineAsyncComponent(() => import('./components/Alert') );
const Popup = defineAsyncComponent(() => import('./components/Popup') );
const ButtonMarketing = defineAsyncComponent(() => import('./components/ButtonMarketing') );

const SiteGuide = defineAsyncComponent(() => import( "./components/kernel/Guide/SiteGuideSetup.vue"));
import siteGuideStore from "./components/kernel/Guide/store/siteGuide";

import Layout from './assets/js/layout'
const Chat = defineAsyncComponent (() => import('./components/Chat') );
import ReportPopupDownload from "./pages/reports/ReportPopupDownload";
import axios from "axios";




export default {
	name: 'App',
	components: {
		//AppNet,
		//LazyHydrate,
		AuthUser,
		BlockManager,
		WebAnalyze,
		Alert,
		Popup,
		ButtonMarketing,
		Chat,
		ReportPopupDownload,
		SiteGuide
	},
	computed: {
		buttonMarketingShow() {

			let result = false;
			if (this.$root.controller && this.$root.user.load) {
				if (["addproject", "addengine", "support", "payments", "settings", "best-pages", "analyzer",
					"page-speed", "cases", "case", "autoseo-client-reviews", "client-testimonials",
					"autoseo-checklist", "fullseo-checklist","project","sproject","rankings"].includes(this.$root.controller)) {
					if (this.$root.user.id == 0 && ['best-pages', 'analyzer', 'page-speed', 'support','dashboard'].includes(this.$root.controller)) result = false;
					else result = true;

					if (this.$root.lang == 'tr') result = false;
				}
				if(result && this.$route.query && this.$route.query.nop){
					result = false;
				}
				console.log(111, this.$root.controller, this.$route.query, result);
			}
			return result;
		}
	},
	data: function () {
		return {
			ll: {},
			hmenu:{
				openMobMenu: false
			},
			load: {
				refAuth: false,
				refBlockManager: false,
				refWebAnalyze: false,
				refChat: false,
				popup: false,
				popupRequest: false
			},
			overlayShow: false,
			appPopup: {
				showPopup: false,
				type: 0,
				input: null,
				email: null
			},
			googleAutoAuth: false,

			lang: window.lang_global || "en",
			languages: ["en", "de", "es", "pt", "fr", "it", "nl", "zh", "tr", "ru", "vi", "gr", "bg", "pl", "fi", "se", "si", "hu", "lt", "sk", "cz", "ro", "jp", "kr", "id", "lv", "th", "no", "ee", "ct", "dk", "rs", "ua", "ba", "mk", "al", "is", "ge", "am", "az", "my", "hr"],
			controller: null,
			multi_lang_default: ["mse-header-footer", "mse-popup-login", "mse-popup-request", "mse-sidebar", "mse-popup"],
			controllers: {
				'index': {
					multi_lang_control: ["mse-popup-analyze", "mse-index"]
				},
				'dsd-quick-tour': {
					multi_lang_control: ["mse-quick-tour"]
				},
				'contacts': {
					multi_lang_control: ["mse-contacts"]
				},
				'cases': {
					multi_lang_control: ["mse-cases", "client-testimonials", "cases"]
				},
				'about': {
					multi_lang_control: ["mse-about"]
				},
				'client-testimonials': {
					multi_lang_control: ["mse-cases", "client-testimonials", "cases"]
				},
				'autoseo': {
					multi_lang_control: ["mse-autoseo", "mse-price", "mse-expert", "mse-popup"]
				},
				'fullseo': {
					multi_lang_control: ["mse-fullseo", "mse-price", "client-testimonials", "mse-cases", "mse-popup"],
					idll: [501, 502, 503, 5350, 8293]
				},
				'fullseo-checklist': {
					multi_lang_control: ["mse-fullseo-checklist"],
				},
				'autoseo-checklist': {
					multi_lang_control: ["mse-autoseo-checklist"],
				},
				'autoseo-client-reviews': {
					multi_lang_control: ["mse-expert"]
				},
				'e-commerce': {
					multi_lang_control: ["mse-e-commerce", "mse-popup", "mse-fullseo", "client-testimonials", "mse-cases"],
					idll: [8293]
				},
				'seo-consultation': {
					multi_lang_control: ["mse-free-consultation"],
					idll: [7970, 8368, 8369, 7900]
				},
				'wechat': {
					multi_lang_control: ["wechat"],
					idll: [8717, 8718]
				},
				'reseller-program': {
					multi_lang_control: ["mse-reseller-program", "mse-cases"],
					idll: [8717, 8718, 8368, 8369, 7900, 8370, 6638, 8072]
				},
				'write-a-review': {
					multi_lang_control: ["mse-write-a-review"],
					idll: [6631, 8717, 8718]
				},
				'analytics': {
					multi_lang_control: ["mse-analytics", "mse-price", "mse-popup-analyze"],
					idll: [7898, 7899, 7900]
				},
				'page-sidebar': {
					multi_lang_control: ["mse-sidebar"], idll: [8071]
				},
				'best-pages': {
					multi_lang_control: ["mse-best-pages", "mse-sidebar", "mse-popup-seo-consultation", "popups", "mse-popup","mse-reports"]
				},
				'page-speed': {
					multi_lang_control: ["mse-popup-seo-consultation", "mse-popup-seo-consultation", "popups", "mse-popup"]
				},
				'analyzer': {
					multi_lang_control: ["mse-popup-seo-consultation", "mse-popup-seo-consultation", "popups", "mse-popup"]
				},
				'price': {
					multi_lang_control: ["mse-price-page", "mse-autoseo", "client-testimonials", "mse-cases", "mse-fullseo", "mse-price", "mse-expert", "mse-popup", "mse-analytics", "mse-popup-analyze", "mse-e-commerce"],
					idll: [8081, 8082, 7970, 8368, 8369, 7900]
				},
				'price-ai': {
					multi_lang_control: ["mse-price-page", "mse-autoseo", "client-testimonials", "mse-cases", "mse-fullseo", "mse-price", "mse-expert", "mse-popup", "mse-analytics", "mse-popup-analyze", "mse-e-commerce"],
					idll: [8081, 8082, 7970, 8368, 8369, 7900]
				},
				'case': {
					multi_lang_control: ["cases", "mse-cases", "client-testimonials"]
				},
				'test': {
					multi_lang_control: ["mse-index"]
				},
				'payment-result': {
					multi_lang_control: ["mse-payments"]
				},
				'unsubscribe': {
					multi_lang_control: ["mse-payments"]
				},
				'payments': {
					multi_lang_control: ["mse-calendar"]
				},
				'dedicated-seo-dashboard': {
					multi_lang_control: ["dedicated-semalt-dashboard"]
				},
				'autoseo-trial': {
					multi_lang_control: ["mse-autoseo", "mse-price", "mse-expert", "mse-popup", "mse-price-page"]
				},
				'lp-website-checker': {
					multi_lang_control: ["mse-website-checker"]
				},
				'addproject': {
					multi_lang_control: ["mse-addproject"]
				},
				'addengine': {
					multi_lang_control: ["mse-addproject", "mse-addengine"]
				},
				'start': {
					multi_lang_control: ["addproject", "mse-addproject", "mse-popup-seo-consultation"]
				},
				'popup': {
					multi_lang_control: ["mse-popup-seo-consultation", "popups"]
				},
				'wow': {
					multi_lang_control: ["old"]
				},
				'dashboard': {
					multi_lang_control: ["old"]
				},
				'affiliate': {
					multi_lang_control: ["mse-calendar"]
				},
				'affiliate-program': {
					multi_lang_control: ["client-testimonials", "mse-cases"],
					idll: [8717, 8718, 8368, 8369, 7900, 8370, 6638, 8072]
				},
				'eugene-serbin-video-message': {
					multi_lang_control: ["video-message"],
				},
				'reports': {
					multi_lang_control: ["mse-calendar", "mse-settings"]
				},
				'serp-simulator-thank-you-for-installing-our-extension': {
					multi_lang_control: ['serp-simulator', 'video-message']
				},
				'seo-audit-thank-you-for-installing-our-extension': {
					multi_lang_control: ['serp-simulator', 'video-message']
				},
				'semalt-thank-you-for-installing-our-extension': {
					multi_lang_control: ['serp-simulator', 'video-message']
				},
				'support':{
					multi_lang_control:[]
				},
				'ssl': {
					multi_lang_control: ["mse-ssl", "ssl"]
				},
				'privacy-policy': {
					multi_lang_control: []
				},
				'terms-of-service': {
					multi_lang_control: []
				},
				'public-offer-agreement': {
					multi_lang_control: []
				},
				'refund-policy': {
					multi_lang_control: []
				},
				'anti-spam-policy': {
					multi_lang_control: ["policy"]
				},
				'settings': {
					multi_lang_control: ["mse-settings"]
				},
				'popup-request':{
					multi_lang_control: ["mse-popup-request"] //, "mse-popup"
				},
				'project': {
					multi_lang_control: ["mse-project","project","popup-rp","rankings","mse-popup-seo-consultation", "popups","mse-calendar", "mse-popup","mse-reports","addproject","notifications","report"]
				},
				'sproject': {
					multi_lang_control: ["mse-project","project","popup-rp","rankings","mse-popup-seo-consultation", "popups","mse-calendar", "mse-popup","mse-reports","addproject","notifications","report"]
				},
				'audit-request': {
					multi_lang_control: ["audit-request"]
				},
				'request-backlinks': {
					multi_lang_control: ["mse-free-consultation", "mse-request-backlinks"],
					idll: [7970, 8368, 8369, 7900]
				},
				'chat-client':{},
				'qa':{
					multi_lang_control: ["header-footer"]
				},
				'rankings':{
					multi_lang_control: ["mse-project","project","popup-rp","rankings","mse-popup-seo-consultation", "popups","mse-calendar", "mse-popup","mse-reports","addproject","notifications","report"]
				},
				'semalt-extension-updated':{
					multi_lang_control: ["semalt-extension-updated"]
				},
				'serp-simulator-extension-updated':{
					multi_lang_control: ["semalt-extension-updated"]
				},
				'seo-audit-extension-updated':{
					multi_lang_control: ["semalt-extension-updated"]
				},
			},
			manager: {
				u_id: 0,
				load: false,
				default: null
			},
			mobileVer: false,
			user: {
				load: false,
				id: 0,
				no_price: false,
				is_seo: false,

			},
			userSettings:{
				us_ca: false
			},
			payment:{
				autoseoClientLoad:false,
				autoseoClient: false,
			},
			uinfo: null,
			sites: {
				load: false,
				sites: null
			},
			langObj: null,
			langdp: null,
			styleChat: {bottom: 30 + 'px'},
			stest: false,
			page: {
				site: null,
				page: null,
				se: null
			},
			sidebar: {
				show: false,
				small: false,
				timerBar: true
			},
			popupDownload: {
				start: false,
				idrg: null
			},
			today: null,
			trigger:{
				reports: {
					listDel:null,
					wlListDel:null,
					delivListDel:null
				}
			},
			seroute:{
				from:null,
				to:null
			}
		}
	},
	methods: {
		async init() {
			this.detectController();
			if(this.controller=='chat-client'){}
			else{
				this.getll();
			}

			if (this.$root.controller && (['popup', 'wow', 'dashboard', 'affiliate', 'popup-request','chat-client'].includes(this.$root.controller))) {

			} else {
				this.load.refAuth = true;
				if (!['contacts'].includes(this.$root.controller)) {
					this.load.refBlockManager = true;
				}
				//this.load.refWebAnalyze = true;
				this.load.refChat = true;
			}

			//setTimeout(() => {
				// this.$root.controller=='popup' || this.$root.controller=='wow' || this.$root.controller=='dashboard' || this.$root.controller=='affiliate'
			//}, 2000);

			if(this.$root.user.load){
				if( this.$root.user.id==0 ) console.log('#google client start 2');
			}

		},
		openMobileMenu() {

		},
		funcPopup(method, obj) {
			if (method == 'restore_account') this.restore_account(obj);
			else if (method == 'delete_template_report' && obj.mm){
				//console.log(222, typeof this.$refs.rView.deleteTemplateReportConfirm )
				//23this.$refs.rView.deleteTemplateReportConfirm(obj.id);
				if(obj.mm=='deleteReport'){
					//this.r_deleteReport(obj.id);
					this.trigger.reports.listDel = obj.id;
					//this.$refs.rView.ReportsList.triggerDel = obj.id;
				}
				else if(obj.mm=='deleteTemplateWhiteLabel'){
					this.trigger.reports.wlListDel = obj.id;
					//this.r_deleteTemplateWhiteLabel(obj.id);
				}
				else if(obj.mm=='deleteTemplateDelivery'){
					this.trigger.reports.delivListDel = obj.id;
					//this.r_deleteTemplateDelivery(obj.id);
				}
			}
			//console.log('funcPopup', obj);
		},
		async detectController() {
			let url = window.location.pathname.split('/');
			if (url && url[1]) {
				if (this.languages.includes(url[1])) {
					this.lang = url[1];
					url.splice(1, 1);
				}
			}
			if (url && url[1]) {
				let found = false;
				let keys = Object.keys(this.controllers);
				if(keys.includes(url[1])){
					this.controller = url[1];
					found = true;
				}
				//console.log('!!!Redirect ', this.controllers, found, url[1], url)
				//if (!found) this.$router.push('/' + this.lang != 'en' ? this.lang : '');
			} else this.controller = 'index';
			console.log('vue3', this.controller);
		},
		async getll() {
			let multi_lang_control = this.multi_lang_default;
			if (['privacy-policy', 'terms-of-service', 'public-offer-agreement', 'refund-policy',
				'anti-spam-policy'].includes(this.controller)) {
				multi_lang_control.push(this.controller);

			}
			//console.log(11111111111111, this.controller)
			multi_lang_control.push('mse-' + this.controller);
			let idll = [1262, 1263, 7898, 7899, 7900, 9767, 9787, 5823, 5824, 5826, 5827, 9954, 8709, 8710, 8711, 8712];
			if (this.controllers[this.controller]) {
				if (this.controllers[this.controller].multi_lang_control) multi_lang_control = multi_lang_control.concat(this.controllers[this.controller].multi_lang_control);
				if (this.controllers[this.controller].idll) idll = idll.concat(this.controllers[this.controller].idll);
			}
			let res = await axios.post('/api/main/get', {
				datall: {
					"lang": this.lang,
					"controller": multi_lang_control,
					"ids": idll
				}
			});
			if (res && res.data) {
				if (res.data.datall) {
					this.ll = res.data.datall;
					if (document.getElementsByTagName('title')[0] && document.getElementsByTagName('title')[0].hasAttribute('data-ll')) {
						let title_id = document.getElementsByTagName('title')[0].getAttribute('data-ll');
						if (title_id && this.ll['i' + title_id]) {
							document.getElementsByTagName('title')[0].text = this.ll['i' + title_id];
						}
					}
				}
				if (res.data.languages) {
					this.langObj = res.data.languages;
					let languages = [];
					let langdp = {};
					for (let jj in this.langObj) {
						languages.push(this.langObj[jj].lang);
						langdp[this.langObj[jj].lang] = this.langObj[jj].datepicker;
					}
					this.languages = languages;
					this.langdp = langdp;
				}
			}
		},
		async mainsemaltf(func, data) {
			let result = {
				result: false
			};
			//console.log('check_ban_domain', data);
			if (func && data) {
				if (func == 'check_ban_domain') {
					if (data.site) {
						let res2 = await this.$children[0].checkBanDomain(data.site);
						if (res2) result.result = true;
						//console.log('check_ban_domain', res2, data);
					}
				}
			}
			return result;
		},
		setTitle(data){

			console.log('#setTitle');
			if(data && data.site) console.log('#site:', data.site);
		}
	},
	mixins: [Layout],
	beforeCreate() {
		if (!this.$store.state['siteGuide']) {
			this.$store.registerModule('siteGuide', siteGuideStore)
		}
	},
	mounted() {
		this.init();
	},
	watch: {
		async '$root.user.load'(to) {
			console.log('#user.load');
			if(to){
				if (this.$root.user.id) {
					if (this.$root.controller && (
						this.$root.controller == 'best-pages' ||
						this.$root.controller == 'analyzer' ||
						this.$root.controller == 'page-speed'||
						this.$root.controller == 'project' ||
						this.$root.controller == 'rankings'
					) ) {

						if(this.$route.query && this.$route.query.nop && this.$route.query.nop!=0){
							/* setTimeout(() => {
								if(this.$refs.popup.obj.showPopup){
									if (this.$root.user && this.$root.user.popups && this.$root.user.popups[0]) {
										if (this.$root.user.popups[0] == 11){
											let type = 17;
											if(!this.$refs.popup.obj.showPopup){
												this.$refs.popup.obj.type = type;
												this.$refs.popup.obj.showPopup = true;
											}
										}
									}
								}
							}, 4000); */
						}
						else{
							setTimeout(() => {
								if (this.$root.user && this.$root.user.popups && this.$root.user.popups[0]) {
									let type = 0;
									if (this.$root.user.popups[0] == 1) type = 18;
									else if (this.$root.user.popups[0] == 2) type = 19;
									else if (this.$root.user.popups[0] == 3) type = 21;
									else if (this.$root.user.popups[0] == 5) type = 20;
									else if (this.$root.user.popups[0] == 11) type = 17;
									if (type) {
										if(!this.$refs.popup.obj.showPopup){
											this.$refs.popup.obj.type = type;
											this.$refs.popup.obj.showPopup = true;
										}
									}
								} else {
									this.checkPopupSeoConsultation();
								}

								if (!this.$refs.popup.obj.showPopup) {
									window.top.postMessage('closePopupSeoAudit', '*');
								}


								/*if(!this.$refs.popup.obj.showPopup){
									if(this.$root.controller && (
										this.$root.controller == 'best-pages' ||
										this.$root.controller == 'project' ||
										this.$root.controller == 'rankings'
									) ) {
										if(this.getCookie('popup31')){}
										else{
											this.$refs.popup.obj.type = 31;
											this.$refs.popup.obj.showPopup = true;
											this.setCookie('popup31',1,{minutes:720});
										}
									}
								}*/
							}, 3000);
						}
					}
				}
				else {
					this.load.refAuth = true;
					this.googleAutoAuth = true;
					console.log('#google client start');
					let loadHandler = () => {
						console.log('#google client gsi');
					};
					setTimeout(() => {
						this.loadJs('https://accounts.google.com/gsi/client', loadHandler)
					}, 4000);
					//this.loadJs('https://accounts.google.com/gsi/client', loadHandler);
				}
			}
		},
		$route(to, from) {
			this.seroute.from = from;
			this.seroute.to = to;
		}
	},
	setup() {
		const refBlockManager = ref(null);  // объявляем реф (с начальным значением null)
		const alert = ref(null);
		const header = ref(null);
		const footer = ref(null);
		const refWebAnalyze = ref(null);
		const rView = ref(null);
		const popup = ref(null);

		onMounted(() => {
			//console.log(refBlockManager.value);
		});
		return {
			refBlockManager,
			refWebAnalyze,
			alert,
			header,
			footer,
			rView,
			popup
		};
	}

}
</script>


<style lang="css">
@import '../public/css/font.css';
@import '../public/css/main.css';
@import '../public/css/modal.css';
@import '../public/css/header.css';
@import '../public/css/footer.css';

body,
html {
	scroll-behavior: smooth;
	overflow-x: hidden;
	min-height: 100vh;
}

a {
	color: #0288D1;
}

.smlt-wrapp {
	display: flex;
	max-width: 100%;
	position: relative;
}
</style>

<script setup>
</script>