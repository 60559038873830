import axios from "axios";
import endpoints from "./endpoints";

class UserModel {
    get (scopes) {}
    changeAccount(id) {}
    logout () {}
    checkForExistence (email) {}
    setSocialAccount (email, groupaccess) {}
    restorPassword (email) {}
    authForm (data) {}
    socialAuthLink (service, hash, redirect = false) {}
    socialAuthLinkPermission (service, group, redirectURI = '/', redirect = false) {}
    socialAuth (service, params) {}
    getFacebookToken () {}
    getSessionToken (scopes) {
        return axios(`${endpoints.apiCom}/user/get/session-token/`, {
            //return axios(`https://semalt.net/api/v1/user/get/session-token/`, {
            method: 'get',
            params: {
                scopes
            },
            withCredentials: true
        })
    }
    getServices(scope) {}
    getUserCustomPopups() {}
}

export default new UserModel()