// import GuideApi from "~/api/guide/guideApi";

const GUIDE_STORAGE_KEY = 'OpenGuideStatus';
const GUIDE_SESSION_STORAGE_KEY = 'OpenGuideStatusLater';

export default {
  namespaced: true,
  state: {
    isOpen: false,
    guideNotification: {
      dashboard: null, //'not_opened',
      dashboard_table_view: null, //'not_opened',
      keywords: null, //'not_opened',
      homepage: null, //'not_opened',
      best_pages: null, //'not_opened',
      competitors: null, //'not_opened',
      one_page: null, //'not_opened',
      website_uniqueness: null, //'not_opened',
      rankings:null
    },
    guideNotificationSession: {
      dashboard: 'not_opened',
      rankings:'not_opened',
      dashboard_table_view: 'not_opened',
      keywords: 'not_opened',
      homepage: 'not_opened',
      best_pages: 'not_opened',
      competitors: 'not_opened',
      one_page: 'not_opened',
      website_uniqueness: 'not_opened'
    },
    showTooltip: false,
  },

  mutations: {
    SET_GUIDE_NOTIFICATION(state, data) {
      state.guideNotification = data;
    },
    CHANGE_NOTIFICATION_STATUS (state, payload) {
      state.guideNotification[payload.key] = payload.value
    },
    CHANGE_NOTIFICATION_SESSION_STATUS (state, payload) {
      state.guideNotificationSession[payload.key] = payload.value;
      sessionStorage.setItem(GUIDE_SESSION_STORAGE_KEY, JSON.stringify(state.guideNotificationSession))
    },
    CHANGE_IS_OPEN_GUIDE (state) {
      state.isOpen = !state.isOpen;
    },
    SET_NOTIFICATION_STATE (state, payload) {
      state.showTooltip = payload
    }
  },

  actions: {
    setGuideState ({dispatch, commit}, firstEnter) {
      // GuideApi.getGuideStatus().then((resp) => {
      //   if(resp.data && resp.data.result) {
      //     commit('SET_GUIDE_NOTIFICATION', resp.data.result);
      //   }
      // });

      localStorage.removeItem('guideOpenStatus');
      localStorage.removeItem('OpenGuideStatus');
      if(firstEnter) {
        dispatch('checkSessionExistance')
      }
    },
    checkSessionExistance ({ state }) {
      let sessionStorageStatus = sessionStorage.getItem(GUIDE_SESSION_STORAGE_KEY);
      if (sessionStorageStatus === null) {
        sessionStorage.setItem(GUIDE_SESSION_STORAGE_KEY, JSON.stringify(state.guideNotificationSession));
      } else {
        state.guideNotificationSession = JSON.parse(sessionStorageStatus)
      }
    },
    setGuideStatus ({commit, state}, payload) {
      if (state.guideNotification[payload.key] === 'not_opened') {
        commit('CHANGE_NOTIFICATION_STATUS', payload);
        //with params it set status of guide (without params get status)
        // GuideApi.getGuideStatus(payload.key)
      }
    }
  },
}
