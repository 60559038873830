//import VueI18n from "vue-i18n";
import { createI18n } from 'vue-i18n';

//0 | ends with 1 | ends with 2-4 | ends with 5-9, 0 and teens (10-19)  0 1 2 3
const slavicPluralization = (choice, choicesLength) => {
    if (choice === 0) {
        return 0;
    }

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    const maxChoices = choicesLength - 1;
    if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : maxChoices; //2;
    }
    if (!teen && endsWithOne) {
        return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return maxChoices >= 2 ? 2 : maxChoices;
    }

    return maxChoices; //(choicesLength < 4) ? 2 : 3
};
let navLang = (window && window.env && window.env.USER_LANG) ? window.env.USER_LANG.toLowerCase() : 'en';
const locales = {
    en: {
        'MMM d, yyyy' : "MMM d, yyyy",
        'MMM dd, yyyy' : "MMM dd, yyyy",
        'MMM d, yyyy HH:mm' : "MMM d, yyyy HH:mm",
        'MMM dd,yyyy, HH:mm' : "MMM dd,yyyy, HH:mm",
        'MMMM d, yyyy h:mm a' : "MMMM d, yyyy h:mm a",
        'MMM dd, yyyy h:mm a' : "MMM dd, yyyy h:mm a"
    },
    ru: {
        'MMM d, yyyy' : "d MMM, yyyy",
        'MMM dd, yyyy' : "dd MMM, yyyy",
        'MMM d, yyyy HH:mm' : "d MMM, yyyy HH:mm",
        'MMM dd,yyyy, HH:mm' : "dd MMM,yyyy, HH:mm",
        'MMMM d, yyyy h:mm a' : "d MMMM, yyyy HH:mm",
        'MMM dd, yyyy h:mm a' : "d MMM, yyyy HH:mm"
    }
};

// export default (Vue) => {
//   Vue.use(VueI18n);
//   return new VueI18n({
//     locale: navLang || 'en',
//     fallbackLocale: 'en',
//     messages: {
//       ...locales
//     },
//     pluralizationRules: {
//       ru: slavicPluralization,
//       uk: slavicPluralization
//     },
//     silentTranslationWarn: true,
//     silentFallbackWarn: true
//   });
// }
export const i18n = createI18n({
    locale: navLang || 'en',
    fallbackLocale: 'en',
    messages: {
        ...locales
    },
    pluralizationRules: {
        ru: slavicPluralization,
        uk: slavicPluralization
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true
});
