const DEFAULT_MSG_DURATION = 3000;
const DEFAULT_MSG_ERROR_TEXT = 'ERROR_MAIN';
const DEFAULT_MSG_SUCCESS_TEXT = 'YOUR_PROFILE_HAS_BEEN_CHANGED';
const state = {
    copiedToClipboardPopOver: false,
    errorSnackbar: {
        state: false,
        msg: '',
    },
    successSnackbar: {
        state: false,
        msg: '',
    },
};
const mutations = {
    SET_COPIED_TO_CLIPBOARD_POP_OVER(state, value){
        state.copiedToClipboardPopOver = value;
    },
    SET_ERROR_SNACKBAR_MSG(state, data) {
        state.errorSnackbar.msg = data
    },
    SET_ERROR_SNACKBAR_STATE(state, data) {
        state.errorSnackbar.state = data
    },
    // SET_ERROR_SNACKBAR_TIMEOUT(state, data) {
    //   state.errorSnackbar.timeout = data
    // },

    SET_SUCCESS_SNACKBAR_MSG (state, data) {
        state.successSnackbar.msg = data
    },
    SET_SUCCESS_SNACKBAR_STATE(state, data) {
        state.successSnackbar.state = data
    },
    // SET_SUCCESS_SNACKBAR_TIMEOUT(state, data) {
    //   state.successSnackbar.timeout = data
    // },
};
const actions = {
    /**
     *
     * @param context
     * @param {String} msg - message to show in snackbar
     * @param {Number} duration - period of time that snackbar is visible for
     */
    showErrorSnackbar({state, commit}, context = {}) {
        const localDuration = (context && context.duration) || DEFAULT_MSG_DURATION;
        commit('SET_ERROR_SNACKBAR_MSG', context && context.msg ? context.msg : DEFAULT_MSG_ERROR_TEXT);
        commit('SET_ERROR_SNACKBAR_STATE', true);
        //clearTimeout(timeout);
        const timeout = setTimeout(() => {
            commit('SET_ERROR_SNACKBAR_STATE', false);
            //commit('SET_ERROR_SNACKBAR_MSG', state.errorSnackbar.default.msg);
            //commit('SET_ERROR_SNACKBAR_TIMEOUT', state.errorSnackbar.default.timeout);
        }, localDuration)
    },
    showSuccessSnackbar ({ state, commit }, context = {}) {
        const localDuration = (context && context.duration) || DEFAULT_MSG_DURATION;
        commit('SET_SUCCESS_SNACKBAR_MSG', context && context.msg ? context.msg : DEFAULT_MSG_SUCCESS_TEXT);
        commit('SET_SUCCESS_SNACKBAR_STATE', true);
        //clearTimeout(timeout);
        const timeout = setTimeout(() => {
            commit('SET_SUCCESS_SNACKBAR_STATE', false);
        }, localDuration)
    },
};

export default {
    state,
    mutations,
    actions
}
