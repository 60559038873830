<template>
    <div v-if="ll">
        <div class="overlay" :class="[blockManager || blockManagerSuccess?'is-visible':'']" id="overlay"></div>
        <template v-if="thisRequest">
            <div class="smlt-modal popup-request" :class="[blockManager?'is-visible':'']" id="modal">
                <div class="smlt-modal-wr">
                    <span @click="closePopupRequest" class="close-btn close" id="close-btn"></span>
                    <BlockManagerReq v-if="newpage" ref="refBlockManagerReq" :page="newpage" :fid="1"></BlockManagerReq>
                </div>
            </div>
            <div v-if="blockManager" class="btn-form" style="display: none;
                "><button type="button" class="btn btn-blue" @click="sendRequestMain">Send</button>
            </div>
        </template>

        <div class="smlt-modal popup-success" id="modal-success" :class="[blockManagerSuccess?'is-visible':'']">
            <div class="smlt-modal-wr">
                <span @click="closeMsg" class="close-btn"></span>
                <div class="popup-success-wr">
                    <img src="/mse/img/check-circle-solid.svg" alt="">
                    <h4>{{ll.i7971||'Thank you'}}</h4>
                    <p>{{ll.i7972}}</p>
                    <span class="close close-text" id="close" @click="closeMsg">{{ll.i7974||'Close'}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref} from 'vue';
    import BlockManagerReq from "./BlockManagerReq"
    export default {
        name: "BlockManager",
        components:{
            BlockManagerReq
        },
        computed:{
            ll(){
                return this.$root.ll;
            }
        },
        data: function() {
            return {
                thisRequest: true,
                blockManager: false,
                blockManagerSuccess: false,
                reload: false,
                topFs: false,
				topSemalt: false,
				newpage: null
            }
        },
        methods: {
            async init(){
				if(this.$route.query && this.$route.query.c){
					if(this.$route.query.c=='fs') this.topFs = true;
					else if(this.$route.query.c=='se') this.topSemalt = true;
				}

				//console.log('#BlockManager init');
				if(this.page){
					this.newpage = this.page;
				}
				else{
					this.newpage = 'default';
				}
            },
			sendRequestMain(){
				this.$children[0].sendRequest(); //каким то образом работает, не трогаем
				//this.$refs.refBlockManagerReq.sendRequest();
				this.blockManager = false;
			},
            closeMsg(){
                if(this.topFs){
                    window.top.postMessage('close', 'https://sites.fastspring.com');
                }
				if(this.topSemalt){
					window.top.postMessage('close', 'https://semalt.com');
				}
				this.blockManagerSuccess=false;
				if(this.reload){
					this.reload = false;
					window.location.reload();
                }
            },
            closePopupRequest(){
                if(this.topFs){
                    window.top.postMessage('close', 'https://sites.fastspring.com');
                }
				if(this.topSemalt){
					window.top.postMessage('close', 'https://semalt.com');
				}
                this.blockManager=false;
            },
			listener(event){
				if(event && event.data && event.origin){
					if(event.origin=='https://sites.fastspring.com'){
						if(event.data=='openPopupRequest'){
							this.blockManager = true;
						}
					}
					else if(event.origin=='https://semalt.com'){
						if(event.data=='close'){
							this.blockManager = false;
						}
						else if(event.data.id && event.data.id=='open'){
							this.blockManager = true;
							if(event.data.typeRequest) this.$refs.refBlockManagerReq.manager.typeRequest = event.data.typeRequest;
							if(event.data.custom) this.$refs.refBlockManagerReq.manager.custom = event.data.custom;
							//console.log( this.$refs.refBlockManagerReq.manager );
						}
						else if(event.data.id && event.data.id=='close'){
							this.blockManager = false;
						}
					}
				}
				//console.log('#listener', event);
			}
        },
        mounted(){
			if (window.addEventListener) {
				window.addEventListener('message', this.listener, false);
			} else {
				window.attachEvent('onmessage', this.listener);
			}

            this.init();
        },
		props: {
			page: String,
		},
		setup() {
			const refBlockManagerReq = ref(null);
			return {
				refBlockManagerReq
			};
		},
		beforeUnmount() {
			if (window.removeEventListener) {
				window.removeEventListener('message', this.listener, false);
			} else {
				window.detachEvent('onmessage', this.listener);
			}
		}
    }
</script>

<style lang="less">
    .smlt-modal.popup-request .request-form .input-form span.vti__selection{
        position: relative!important;
        right: unset!important;
    }
    @media screen and (max-width: 600px) {
      .smlt-modal.popup-request .request-form .input-form-wr{
        display: block!important;
      }
      .smlt-modal.popup-request .request-form .btn-form {
        position: absolute;
        bottom: -130px;
        margin: auto;
        width: 100%;
        left: 0;
        padding: 0 20px 30px;
      }

      .smlt-modal.popup-request .request-form .btn-form .btn {
        margin: 0 auto;
        width: 100%;
        max-width: 100%;
      }

      .smlt-modal.popup-request .request-title .title-img img{
        width: 100px!important;
        height: 100px!important;
      }

      .smlt-modal.popup-request .request-title .title-contact h6{
        font-size: 12px!important;
      }
    }
    @media screen and (max-width: 1024px) {
        .smlt-modal.popup-success .popup-success-wr {
            position: relative;
            margin-top: 50%;
            top:-80px;
        }
    }

</style>