import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const AppHeader = () => import( /*  webpackChunkName: "Header", webpackPreload: true, webpackMode:"lazy"  */ '../pages/Header.vue');
const AppFooter = () => import(/*  webpackChunkName: "Footer", webpackPreload: true, webpackMode:"lazy"  */ '../pages/Footer.vue');

let routesArr = [
  /*{
    path: '/home-vue',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-vue',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },*/
  {
    name: 'Support',
    path: '/support',
    components: {
      header: AppHeader,
      default: () => import('../pages/Support.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SupportLang',
    path: '/:lang/support',
    components: {
      header: AppHeader,
      default: () => import('../pages/Support.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'index',
    path: '/',
    components: {
      header: AppHeader,
      default:()=> import('../pages/Main.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Autoseo',
    path: '/autoseo',
    components: {
      header: AppHeader,
      default: () => import('../pages/Autoseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AutoseoLang',
    path: '/:lang/autoseo',
    components: {
      header: AppHeader,
      default: () => import('../pages/Autoseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Fullseo',
    path: '/fullseo',
    components: {
      header: AppHeader,
      default: () => import('../pages/Fullseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'FullseoLang',
    path: '/:lang/fullseo',
    components: {
      header: AppHeader,
      default: () => import('../pages/Fullseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ECommerce',
    path: '/e-commerce',
    components: {
      header: AppHeader,
      default: () => import('../pages/ECommerce.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ECommerceLang',
    path: '/:lang/e-commerce',
    components: {
      header: AppHeader,
      default: () => import('../pages/ECommerce.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Analytics',
    path: '/analytics',
    components: {
      header: AppHeader,
      default: () => import('../pages/Analytics.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AnalyticsLang',
    path: '/:lang/analytics',
    components: {
      header: AppHeader,
      default: () => import('../pages/Analytics.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'FreeConsultation',
    path: '/seo-consultation',
    components: {
      header: AppHeader,
      default: () => import('../pages/FreeConsultation.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'FreeConsultationLang',
    path: '/:lang/seo-consultation',
    components: {
      header: AppHeader,
      default: () => import('../pages/FreeConsultation.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AuditRequest',
    path: '/audit-request',
    components: {
      header: AppHeader,
      default: () => import('../pages/AuditRequest.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AuditRequestLang',
    path: '/:lang/audit-request',
    components: {
      header: AppHeader,
      default: () => import('../pages/AuditRequest.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'DedicatedSeoDashboard',
    path: '/dedicated-seo-dashboard',
    components: {
      header: AppHeader,
      default: () => import('../pages/DedicatedSeoDashboard.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'DedicatedSeoDashboardLang',
    path: '/:lang/dedicated-seo-dashboard',
    components: {
      header: AppHeader,
      default: () => import('../pages/DedicatedSeoDashboard.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WebsiteChecker',
    path: '/lp-website-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/WebsiteChecker.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WebsiteCheckerLang',
    path: '/:lang/lp-website-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/WebsiteChecker.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Ssl',
    path: '/ssl',
    components: {
      header: AppHeader,
      default: () => import('../pages/Ssl.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SslLang',
    path: '/:lang/ssl',
    components: {
      header: AppHeader,
      default: () => import('../pages/Ssl.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AutoseoTrial',
    path: '/autoseo-trial',
    components: {
      header: AppHeader,
      default: () => import('../pages/AutoseoTrial.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AutoseoTrialLang',
    path: '/:lang/autoseo-trial',
    components: {
      header: AppHeader,
      default: () => import('../pages/AutoseoTrial.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PrivacyPolicy',
    path: '/privacy-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/PrivacyPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PrivacyPolicyLang',
    path: '/:lang/privacy-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/PrivacyPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'TermsOfService',
    path: '/terms-of-service',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/TermsOfService.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'TermsOfServiceLang',
    path: '/:lang/terms-of-service',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/TermsOfService.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PublicOfferAgreement',
    path: '/public-offer-agreement',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/PublicOfferAgreement.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PublicOfferAgreementLang',
    path: '/:lang/public-offer-agreement',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/PublicOfferAgreement.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'RefundPolicy',
    path: '/refund-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/RefundPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'RefundPolicyLang',
    path: '/:lang/refund-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/RefundPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AntiSpamPolicy',
    path: '/anti-spam-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/AntiSpamPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AntiSpamPolicyLang',
    path: '/:lang/anti-spam-policy',
    components: {
      header: AppHeader,
      default: () => import('../pages/policy/AntiSpamPolicy.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'FullseoChecklist',
    path: '/fullseo-checklist',
    components: {
      header: AppHeader,
      default: () => import('../pages/FullseoChecklist.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'FullseoChecklistLang',
    path: '/:lang/fullseo-checklist',
    components: {
      header: AppHeader,
      default: () => import('../pages/FullseoChecklist.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AutoseoChecklist',
    path: '/autoseo-checklist',
    components: {
      header: AppHeader,
      default: () => import('../pages/AutoseoChecklist.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AutoseoChecklistLang',
    path: '/:lang/autoseo-checklist',
    components: {
      header: AppHeader,
      default: () => import('../pages/AutoseoChecklist.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ContactUs',
    path: '/contacts',
    components: {
      header: AppHeader,
      default: () => import('../pages/ContactUs.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ContactUsLang',
    path: '/:lang/contacts',
    components: {
      header: AppHeader,
      default: () => import('../pages/ContactUs.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Affiliate',
    path: '/affiliate',
    components: {
      header: AppHeader,
      default: () => import('../pages/Affiliate.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateLang',
    path: '/:lang/affiliate',
    components: {
      header: AppHeader,
      default: () => import('../pages/Affiliate.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateProgram',
    path: '/affiliate-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/AffiliateProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateProgramLang',
    path: '/:lang/affiliate-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/AffiliateProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Cases',
    path: '/cases',
    components: {
      header: AppHeader,
      default: () => import('../pages/Cases.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'CasesLang',
    path: '/:lang/cases',
    components: {
      header: AppHeader,
      default: () => import('../pages/Cases.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Case',
    path: '/case/:case_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/Case.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'CaseLang',
    path: '/:lang/case/:case_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/Case.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QuickTour',
    path: '/dsd-quick-tour',
    components: {
      header: AppHeader,
      default: () => import('../pages/QuickTour.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QuickTourLang',
    path: '/:lang/dsd-quick-tour',
    components: {
      header: AppHeader,
      default: () => import('../pages/QuickTour.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'About',
    path: '/about',
    components: {
      header: AppHeader,
      default: () => import('../pages/About.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AboutLang',
    path: '/:lang/about',
    components: {
      header: AppHeader,
      default: () => import('../pages/About.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Testimonials',
    path: '/client-testimonials',
    components: {
      header: AppHeader,
      default: () => import('../pages/Testimonials.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'TestimonialsLang',
    path: '/:lang/client-testimonials',
    components: {
      header: AppHeader,
      default: () => import('../pages/Testimonials.vue'),
      footer: AppFooter
    }
  },
  /* ************* Blog routes start ************* */
  {
    name: 'Blog',
    path: '/blog',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogCat',
    path: '/blog/category/:cat_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogTag',
    path: '/blog/tag/:tag_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogSearch',
    path: '/blog/search/',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogSingle',
    path: '/blog/:post_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Post.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogLang',
    path: '/:lang/blog',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogCatLang',
    path: '/:lang/blog/category/:cat_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogTagLang',
    path: '/:lang/blog/tag/:tag_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Blog.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BlogSingleLang',
    path: '/:lang/blog/:post_slug',
    components: {
      header: AppHeader,
      default: () => import('../pages/blog/Post.vue'),
      footer: AppFooter
    }
  },


  {
    name: 'SuccessStories',
    path: '/autoseo-client-reviews',
    components: {
      header: AppHeader,
      default: () => import('../pages/SuccessStories.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SuccessStoriesLang',
    path: '/:lang/autoseo-client-reviews',
    components: {
      header: AppHeader,
      default: () => import('../pages/SuccessStories.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Wechat',
    path: '/wechat',
    components: {
      header: AppHeader,
      default: () => import('../pages/Wechat.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WechatLang',
    path: '/:lang/wechat',
    components: {
      header: AppHeader,
      default: () => import('../pages/Wechat.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ResellerProgram',
    path: '/reseller-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/ResellerProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ResellerProgramLang',
    path: '/:lang/reseller-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/ResellerProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WriteReview',
    path: '/write-a-review',
    components: {
      header: AppHeader,
      default: () => import('../pages/WriteReview.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WriteReviewLang',
    path: '/:lang/write-a-review',
    components: {
      header: AppHeader,
      default: () => import('../pages/WriteReview.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Affiliate',
    path: '/affiliate',
    components: {
      header: AppHeader,
      default: () => import('../pages/Affiliate.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateLang',
    path: '/:lang/affiliate',
    components: {
      header: AppHeader,
      default: () => import('../pages/Affiliate.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateProgram',
    path: '/affiliate-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/AffiliateProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AffiliateProgramLang',
    path: '/:lang/affiliate-program',
    components: {
      header: AppHeader,
      default: () => import('../pages/AffiliateProgram.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Price',
    path: '/price',
    components: {
      header: AppHeader,
      default: () => import('../pages/price/Price.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PriceLang',
    path: '/:lang/price',
    components: {
      header: AppHeader,
      default: () => import('../pages/price/Price.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PriceAi',
    path: '/price-ai',
    components: {
      header: AppHeader,
      default: () => import('../pages/price/Price.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PriceAiLang',
    path: '/:lang/price-ai',
    components: {
      header: AppHeader,
      default: () => import('../pages/price/Price.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Insights',
    path: '/insights',
    components: {
      header: AppHeader,
      default: () => import('../pages/SemaltInsights.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'InsightsLang',
    path: '/:lang/insights',
    components: {
      header: AppHeader,
      default: () => import('../pages/SemaltInsights.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PageSpeed',
    path: '/page-speed',
    components: {
      header: AppHeader,
      default: () => import('../pages/PageSpeed.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PageSpeedLang',
    path: '/:lang/page-speed',
    components: {
      header: AppHeader,
      default: () => import('../pages/PageSpeed.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BestPages',
    path: '/best-pages',
    components: {
      header: AppHeader,
      default: () => import('../pages/BestPages.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'BestPagesLang',
    path: '/:lang/best-pages',
    components: {
      header: AppHeader,
      default: () => import('../pages/BestPages.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WebpageAnalyzer',
    path: '/analyzer',
    components: {
      header: AppHeader,
      default: () => import('../pages/WebpageAnalyzer.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WebpageAnalyzerLang',
    path: '/:lang/analyzer',
    components: {
      header: AppHeader,
      default: () => import('../pages/WebpageAnalyzer.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AppWebsiteChecker',
    path: '/website-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/AppWebsiteChecker.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AppWebsiteCheckerLang',
    path: '/:lang/website-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/AppWebsiteChecker.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Settings',
    path: '/settings',
    components: {
      header: AppHeader,
      default: () => import('../pages/Settings.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SettingsLang',
    path: '/:lang/settings',
    components: {
      header: AppHeader,
      default: () => import('../pages/Settings.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Payments',
    path: '/payments',
    components: {
      header: AppHeader,
      default: () => import('../pages/Payments.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PaymentsLang',
    path: '/:lang/payments',
    components: {
      header: AppHeader,
      default: () => import('../pages/Payments.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Unsubscribe',
    path: '/unsubscribe',
    components: {
      header: AppHeader,
      default: () => import('../pages/Unsubscribe.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PaymentResult',
    path: '/payment-result/:status*',
    components: {
      header: AppHeader,
      default: () => import('../pages/PaymentResult.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'PaymentResultLang',
    path: '/:lang/payment-result/:status*',
    components: {
      header: AppHeader,
      default: () => import('../pages/PaymentResult.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Addproject',
    path: '/addproject',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addproject.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AddprojectLang',
    path: '/:lang/addproject',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addproject.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AddprojectAll',
    path: '/addproject/:vald',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addproject.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AddprojectAllLang',
    path: '/:lang/addproject/:vald',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addproject.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Addengine',
    path: '/addengine/:ids',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addengine.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AddengineLang',
    path: '/:lang/addengine/:ids',
    components: {
      header: AppHeader,
      default: () => import('../pages/Addengine.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Start',
    path: '/start',
    components: {
      header: AppHeader,
      default: () => import('../pages/Start.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'StartLang',
    path: '/:lang/start',
    components: {
      header: AppHeader,
      default: () => import('../pages/Start.vue'),
      footer: AppFooter
    }
  },

  {
    name: 'Reports',
    path: '/reports',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsList.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsLang',
    path: '/:lang/reports',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsList.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage10',
    path: '/reports/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsEdit.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang10',
    path: '/:lang/reports/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsEdit.vue'), footer: AppFooter }
  },

  {
    name: 'Reports2',
    path: '/reports/create',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsCreate.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsLang2',
    path: '/:lang/reports/create',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsCreate.vue'), footer: AppFooter }
  },

  {
    name: 'Reports3',
    path: '/reports/create-delivery',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsCreateDelivery.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsLang3',
    path: '/:lang/reports/create-delivery',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsCreateDelivery.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage4',
    path: '/reports/delivery',
    components: { header: AppHeader, default:() => import('../pages/reports/ListDelivery.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang4',
    path: '/:lang/reports/delivery',
    components: { header: AppHeader, default:() => import('../pages/reports/ListDelivery.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage5',
    path: '/reports/delivery/new',
    components: { header: AppHeader, default:() => import('../pages/reports/DeliveryNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang5',
    path: '/:lang/reports/delivery/new',
    components: { header: AppHeader, default:() => import('../pages/reports/DeliveryNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage6',
    path: '/reports/delivery/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/DeliveryNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang6',
    path: '/:lang/reports/delivery/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/DeliveryNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage7',
    path: '/reports/white-label',
    components: { header: AppHeader, default:() => import('../pages/reports/ListWhiteLabel.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang7',
    path: '/:lang/reports/white-label',
    components: { header: AppHeader, default:() => import('../pages/reports/ListWhiteLabel.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage8',
    path: '/reports/white-label/new',
    components: { header: AppHeader, default:() => import('../pages/reports/WhiteLabelNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang8',
    path: '/:lang/reports/white-label/new',
    components: { header: AppHeader, default:() => import('../pages/reports/WhiteLabelNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPage9',
    path: '/reports/white-label/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/WhiteLabelNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsPageLang9',
    path: '/:lang/reports/white-label/edit/:pid',
    components: { header: AppHeader, default:() => import('../pages/reports/WhiteLabelNew.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsMsg',
    path: '/reports/:rpopup/:sid',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsList.vue'), footer: AppFooter }
  },
  {
    name: 'ReportsLangMsg',
    path: '/:lang/reports/:rpopup/:sid',
    components: { header: AppHeader, default:() => import('../pages/reports/ReportsList.vue'), footer: AppFooter }
  },

  {
    name: 'VideoMessage',
    path: '/eugene-serbin-video-message',
    components: { header: AppHeader, default:() => import('../pages/VideoMessage.vue'), footer: AppFooter }
  },
  {
    name: 'VideoMessageLang',
    path: '/:lang/eugene-serbin-video-message',
    components: { header: AppHeader, default:() => import('../pages/VideoMessage.vue'), footer: AppFooter }
  },


  {
    name: 'SerpSimulator',
    path: '/serp-simulator-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSimulator.vue'), footer: AppFooter }
  },
  {
    name: 'SerpSimulatorLang',
    path: '/:lang/serp-simulator-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSimulator.vue'), footer: AppFooter }
  },
  {
    name: 'SerpSeoAuditThank',
    path: '/seo-audit-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSeoAuditThank.vue'), footer: AppFooter }
  },
  {
    name: 'SerpSeoAuditThankLang',
    path: '/:lang/seo-audit-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSeoAuditThank.vue'), footer: AppFooter }
  },
  {
    name: 'SerpSemaltThank',
    path: '/semalt-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSemaltThank.vue'), footer: AppFooter }
  },
  {
    name: 'SerpSemaltThankLang',
    path: '/:lang/semalt-thank-you-for-installing-our-extension',
    components: { header: AppHeader, default:() => import('../pages/SerpSemaltThank.vue'), footer: AppFooter }
  },
  {
    name: 'PopupPage',
    path: '/popup/:id_popup',
    components: {
      default: () => import('../pages/PopupPage.vue')
    }
  },
  {
    name: 'PopupPageLang',
    path: '/:lang/popup/:id_popup',
    components: {
      default: () => import('../pages/PopupPage.vue')
    }
  },
  {
    name: 'WhatsnewLang',
    path: '/:lang/whatsnew',
    components: {
      header: AppHeader,
      default: () => import('../pages/Whatsnew.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Whatsnew',
    path: '/whatsnew',
    components: {
      header: AppHeader,
      default: () => import('../pages/Whatsnew.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Project',
    path: '/project/' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Project.vue'),
      footer: AppFooter
    },
    props: true,
  },
  {
    name: 'ProjectId',
    path: '/project/:idp',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/project', query: { idp: to.params.idp } }
    },
    props: true,
  },
    //ProjectGroup
  {
    name: 'ProjectGroup',
    path: '/project/group' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Groups.vue'),
      footer: AppFooter
    },
    props: true,
  },
  {
    name: 'ProjectLang',
    path: '/:lang/project/' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Project.vue'),
      footer: AppFooter
    },
    props: true,
  },
  {
    name: 'ProjectIdLang',
    path: '/:lang/project/:idp',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/project', query: { idp: to.params.idp } }
    },
    props: true,
  },
  //ProjectGroup
  {
    name: 'ProjectGroupLang',
    path: '/:lang/project/group' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Groups.vue'),
      footer: AppFooter
    },
    props: true,
  },

  {
    name: 'SProject',
    path: '/sproject/' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Project.vue'),
      footer: AppFooter
    },
    props: true,
  },
  {
    name: 'SProjectId',
    path: '/sproject/:idp',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/sproject', query: { idp: to.params.idp } }
    },
    props: true,
  },
  //ProjectGroup
  {
    name: 'SProjectGroup',
    path: '/sproject/group' +
        '',
    components: {
      header: AppHeader,
      default: () => import('../pages/project/Groups.vue'),
      footer: AppFooter
    },
    props: true,
  },

  {
    name: 'DashboardLangT',
    path: '/:lang/dashboard',
    components: {
      default: () => import('../pages/Dashboard.vue')
    }
  },
  {
    name: 'DashboardLang2',
    path: '/:lang/dashboard/:id_project',
    components: {
      default: () => import('../pages/Dashboard.vue')
    }
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    components: {
      default: () => import('../pages/Dashboard.vue')
    }
  },
  {
    name: 'DashboardLang',
    path: '/dashboard/:id_project',
    components: {
      default: () => import('../pages/Dashboard.vue')
    }
  },
  {
    name: 'Wow',
    path: '/wow',
    components: {
      // header: AppHeader,
      default: () => import('../pages/Wow.vue'),
      // footer: AppFooter
    }
  },
  {
    name: 'WowLang',
    path: '/:lang/wow',
    components: {
      // header: AppHeader,
      default: () => import('../pages/Wow.vue'),
      // footer: AppFooter
    }
  },
  {
    name: 'RequestBacklinks',
    path: '/request-backlinks',
    components: {
      header: AppHeader,
      default: () => import('../pages/RequestBacklinks.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'RequestBacklinksLang',
    path: '/:lang/request-backlinks',
    components: {
      header: AppHeader,
      default: () => import('../pages/RequestBacklinks.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Worldwideseo',
    path: '/worldwideseo/:url',
    components: {
      header: AppHeader,
      default: () => import('../pages/Worldwideseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'WorldwideseoLang',
    path: '/:lang/worldwideseo/:url',
    components: {
      header: AppHeader,
      default: () => import('../pages/Worldwideseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Worldseo',
    path: '/worldseo/:url',
    components: {
      header: AppHeader,
      default: () => import('../pages/Worldwideseo.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'ChatClient',
    path: '/chat-client',
    components: {
      default: () => import('../pages/ChatClient.vue'),
    }
  },
  {
    name: 'PopupRequest',
    path: '/popup-request',
    components: {
      default: () => import('../pages/PopupRequest.vue')
    }
  },
  {
    name: 'PopupRequestLang',
    path: '/:lang/popup-request',
    components: {
      default: () => import('../pages/PopupRequest.vue')
    }
  },
  {
    name: 'QaIndex',
    path: '/qa',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QaIndexLang',
    path: '/:lang/qa',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Qa',
    path: '/qa/:page(.*)',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QaLang',
    path: '/:lang/qa/:page(.*)',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QaType',
    path: '/qa/:page/:pageLink(.*)',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'QaTypeLang',
    path: '/:lang/qa/:page/:pageLink(.*)',
    components: {
      header: AppHeader,
      default: () => import('../pages/Qa.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'Rankings',
    path: '/rankings',
    components: {
      header: AppHeader,
      default: () => import('../pages/rankings/Rankings.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'RankingsLang',
    path: '/:lang/rankings',
    components: {
      header: AppHeader,
      default: () => import('../pages/rankings/Rankings.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'HreflangChecker',
    path: '/hreflang-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/HreflangToolPage.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'HreflangCheckerLang',
    path: '/:lang/hreflang-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/HreflangToolPage.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SemaltExtensionUpdated',
    path: '/semalt-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/ExtensionsUpdatedPage.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SemaltExtensionUpdatedLang',
    path: '/:lang/semalt-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/ExtensionsUpdatedPage.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SerpSimulatorExtensionUpdated',
    path: '/serp-simulator-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/SerpSimulatorExtensionUpdated.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SerpSimulatorExtensionUpdatedLang',
    path: '/:lang/serp-simulator-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/SerpSimulatorExtensionUpdated.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SeoAuditExtensionUpdated',
    path: '/seo-audit-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/SeoAuditExtensionUpdated.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'SeoAuditExtensionUpdatedLang',
    path: '/:lang/seo-audit-extension-updated',
    components: {
      header: AppHeader,
      default: () => import('../pages/extensions-updated/SeoAuditExtensionUpdated.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AccessibilityChecker',
    path: '/accessibility-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/AccessibilityChecker.vue'),
      footer: AppFooter
    }
  },
  {
    name: 'AccessibilityCheckerLang',
    path: '/:lang/accessibility-checker',
    components: {
      header: AppHeader,
      default: () => import('../pages/AccessibilityChecker.vue'),
      footer: AppFooter
    }
  },

  // {
  //   name: 'Rankings',
  //   path: '/table',
  //   components: {
  //     header: AppHeader,
  //     default: () => import('../pages/rankings/Rankings.vue'),
  //     footer: AppFooter
  //   }
  //},
  //{
  //   name: 'RankingsLang',
  //   path: '/:lang/table',
  //   components: {
  //     header: AppHeader,
  //     default: () => import('../pages/rankings/Rankings.vue'),
  //     footer: AppFooter
  //   }
  //},
  { path: '/:pathMatch(.*)*', components: {
      header: AppHeader,
      default:()=> import('../pages/Main.vue'),
      footer: AppFooter
    }
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL.replace('/apse','')),
  routes: routesArr
})

export default router
