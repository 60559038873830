import projectsListApi from "../models/projectsListComApi";
import {root} from '../main';

const PROJECTS_LIST_LOAD_STEP = 600;
const PROJECTS_LIST_DEF_SCOPES = 'id,se,tags';
const PROJECTS_LIST_MAX_REQUEST_ARRAY = 3;

async function projectsListGet({filter = '', sites_offset = 0, sites_limit = 100, scopes = "id,se,tags", order, date_order}, projectsListApi) {
    const sort = date_order ? {date_order: date_order} : {order: order || 'asc'};
    try {

        const res = await projectsListApi.get({filter, sites_offset, sites_limit, scopes, ...sort});
        if (res.data.error) {
            throw `${res.data.error}`;
        } else {
            return Promise.resolve(res.data.result);
        }
    } catch (e) {
        return Promise.reject(e)
    }
};

async function projectsListAddFunc(sites, projectsListApi, noSitesAll) {
    let propSites, propSitesAll;

    if (noSitesAll && noSitesAll.page && noSitesAll.page.length > 0) {
        propSites = [noSitesAll];
        propSitesAll = [];
    } else {
        if (Array.isArray(sites) && sites.length > 0) {
            propSitesAll = sites;
        } else if (typeof sites === 'object' && sites) {
            propSitesAll = [sites];
        }
        propSites = [];
    }

    try {
        if (!propSites) {
            throw `projects must be Object | Array of Objects, value passed: ${sites}`;
        }
        const res = await projectsListApi.add(propSitesAll, propSites);

        if (res.data.error) {
            throw `${res.data.error}`;
        } else {
            if (propSites.length > 0) {
                return Promise.resolve(res.data);
            } else {
                return Promise.resolve(res.data.result);
            }

        }
    } catch (e) {
        return Promise.reject(e);
    }
};

async function projectsListDelete(sites, projectsListApi) {
    let propSites;
    if (Array.isArray(sites) && sites.length > 0) {
        propSites = sites;
    } else if (typeof sites === 'string') {
        propSites = [sites];
    }
    try {
        if (!propSites) {
            throw `sites must be String | Array of String, value passed: ${sites}`;
        }
        const res = await projectsListApi.delete(propSites);
        if (res.data.error) {
            throw `${res.data.error}`;
        } else {
            //return Promise.resolve(res.data.result);
            return Promise.resolve(propSites);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

async function projectsListGetByDomain(site, projectsListApi) {
    try {
        if (typeof site !== 'string') {
            throw `site must be String`;
        }
        const res = await projectsListApi.getByDomain(site);
        if (res.data.error) {
            throw `${res.data.error}`;
        } else {
            return Promise.resolve(res.data.result);
        }
    } catch (e) {
        return Promise.reject(e);
    }
};

async function projectInfoGet(ids) {
    try {
        const res = await projectsListApi.getProjectInfo(ids);
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e)
    }
};

async function projectArchivedGet(params) {
    try {
        const res = await projectsListApi.getProjectArchived(params);
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e)
    }
};

async function getAllPagesList(projects) {
    if(!projects || projects.length === 0) {
        return []
    }
    const pageList = [];
    projects.forEach((project) => {
        if(project.pages) {
            const {pages, ...elseProp} = project;
            pages.map((item) => {return {...elseProp, ...item}}).forEach((el) => {
                if(!pageList.some(i => i.page === el.page)) {
                    pageList.push(el)
                }
            })
            //pageList.push(...pages.map((item) => {return {...elseProp, ...item}}))

        } else if(project.page) {
            pageList.push(project)
        }

    });
    return pageList;
}


const getProjectListRequestsCount = (total, offset, step) => {
    const requestLeft = Math.ceil(total / step) - (offset / step);
    return requestLeft < PROJECTS_LIST_MAX_REQUEST_ARRAY ? requestLeft || 0 : PROJECTS_LIST_MAX_REQUEST_ARRAY;
};
const projectsListResultFormatter = (sites, isAll) => {

    if (!sites || !(typeof sites === 'object') || (Array.isArray(sites) && sites.length === 0)) {
        return [];
    } else if (isAll) {
        return Object.keys(sites.sites).map((site) => {

            const pages = sites['sites_all'].filter(item => item.site === site);
            return {
                ...sites.sites[site],
                site: site,
                id: sites.sites[site].id || sites.sites[site].id_site,
                tags: sites.sites[site].tags || [],
                pages: pages
                //seo:seo
            }
        });
    } else if (Array.isArray(sites)) {

        return sites.map((site) => {
            return {
                ...site,
                site: site.site,
                id: site.id || site.id_site,
                tags: site.tags || []
            }
        });
    } else {

        return Object.keys(sites).map((site) => {
            return {
                ...sites[site],
                site: site,
                id: sites[site].id || sites[site].id_site,
                tags: sites[site].tags || []
            }
        });
    }
};
const _projectsListFetch = async ({filter = '', sites_offset = 0, sites_limit = 100, scopes = "id,se,tags", getCount = false}) => {
    const result = await projectsListGet({filter, sites_offset, sites_limit, scopes, order: 'asc'}, projectsListApi);
    const tempList = projectsListResultFormatter(result, true);
    return Promise.resolve(getCount ? {count: result.countFilter, sites: tempList} : tempList);
};
const _projectsListFetchMulti = async ({requests, offset}) => {
    const promiseArr = [];
    for (let i = 0; i < requests; i++) {
        promiseArr.push(
            _projectsListFetch({
                sites_offset: offset + PROJECTS_LIST_LOAD_STEP * i,
                sites_limit: PROJECTS_LIST_LOAD_STEP,
                scopes: PROJECTS_LIST_DEF_SCOPES
            })
        )
    }
    const responseAll = await Promise.all(promiseArr);
    return Promise.resolve(responseAll.flat());
};


export default {
    namespaced: true,
    state: {
        projectsList: [],
        projectsIsLoading: true,
        projectsLoaded: 0,
        projectsTotal: 0,
        projectsOffset: 0,
        projectSeo: ''
    },
    mutations: {
        SET_PR_LIST(state, data) {
            state.projectsList = Array.isArray(data) ? data : [];
        },
        SET_PR_SEO_PACKAGE(state, data) {
            state.projectSeo = data;
        },
        SET_PR_IS_LOADING(state, data) {
            state.projectsIsLoading = data;
        },
        SET_PR_LOADED(state, data) {
            state.projectsLoaded = data || 0;
        },
        SET_PR_TOTAL(state, data) {
            state.projectsTotal = data || 0;
        },
        SET_PR_OFFSET(state, data) {
            state.projectsOffset = data || 0;
        },
        PUSH_TO_PROJECTS_LIST(state, data) {
            state.projectsList.push(...data);
        },
        DELETE_PROJECTS_FROM_LIST(state, data) {
            state.projectsList = state.projectsList.filter(el => !data.includes(el.site))
        },
        UPDATE_PROJECTS_LIST(state, data) {
            const dataFiltered = data
                .filter(el => state.projectsList.findIndex(project => el.site === project.site) === -1) || [];
            state.projectsList = [...state.projectsList, ...dataFiltered]
        },
    },
    actions: {
        async projectsListFetchSort({state, commit}, payload) {
            const result = await projectsListGet({
                filter: '',
                sites_offset: 0,
                scopes: PROJECTS_LIST_DEF_SCOPES,
                sites_limit: payload.limit,
                order: payload.order,
                date_order: payload.date_order,
            }, projectsListApi);
            commit('SET_PR_TOTAL', result.countFilter);
            const tempList = projectsListResultFormatter(result, true);
            commit('SET_PR_LIST', tempList);
        },
        async projectsListLoad({state, commit, dispatch, getters}, loadMore = false) {
            commit('SET_PR_IS_LOADING', true);
            try {
                let projects;
                //console.log('loadProjects');
                if (loadMore) {
                    const tempOffset = state.projectsOffset;
                    const requests = getProjectListRequestsCount(state.projectsTotal, tempOffset, PROJECTS_LIST_LOAD_STEP);
                    projects = await _projectsListFetchMulti({requests: requests, offset: tempOffset});
                    commit('SET_PR_OFFSET', tempOffset + PROJECTS_LIST_LOAD_STEP * requests);
                } else {
                    if (state.projectsList.length > 0) {
                        await dispatch('projectsListStateReset')
                    }
                    const resp = await _projectsListFetch({
                        sites_offset: state.projectsOffset,
                        sites_limit: PROJECTS_LIST_LOAD_STEP,
                        scopes: PROJECTS_LIST_DEF_SCOPES,
                        getCount: true
                    });
                    projects = resp.sites;
                    commit('SET_PR_OFFSET', state.projectsOffset + PROJECTS_LIST_LOAD_STEP);
                    commit('SET_PR_TOTAL', resp.count);
                }

                if (projects) {
                    commit('PUSH_TO_PROJECTS_LIST', projects);
                    commit('SET_PR_LOADED', state.projectsLoaded + projects.length);
                    if (state.projectsLoaded < state.projectsTotal && projects.length > 0) {
                        await dispatch('projectsListLoad', true); //recursive call
                    }
                }
            } finally {
                commit('SET_PR_IS_LOADING', false);
            }

        },
        async projectsListStateReset({commit}) {
            commit("SET_PR_LIST", []);
            commit('SET_PR_LOADED', 0);
            commit('SET_PR_TOTAL', 0);
            commit('SET_PR_OFFSET', 0);
        },
        async projectsListAdd({state, getters, commit, dispatch}, {projects, pushToList = true}) {
            const projectsFiltered = pushToList ? projects.filter(el => state.projectsList.findIndex(project => el.site === project.site) === -1) || [] : projects;

            if (projectsFiltered.length === 0) {
                return;
            }

            const result = await projectsListAddFunc(projectsFiltered, projectsListApi, []);
            const formattedList = projectsListResultFormatter(result);
            if (pushToList) {
                commit("PUSH_TO_PROJECTS_LIST", formattedList);
                const count = state.projectsList.length;
                commit('SET_PR_TOTAL', count);
                commit('SET_PR_LOADED', count);
            }
            return Promise.resolve(formattedList);
        },
        async projectsListAddBySe({state, getters, commit, dispatch}, projects) {
            const result = await projectsListAddFunc([], projectsListApi, projects);
            const formattedList = {
                se: result.se,
                site: result.page,
                id: result.id,
                tags: [],
                pages: result.page,
                mt: result.mt
            };

            return Promise.resolve(formattedList);
        },
        async projectsListDelete({state, getters, commit, dispatch}, {sites}) {
            if (!sites || !sites.length) return;
            const sitesFormatted = await projectsListDelete(sites, projectsListApi);
            commit('DELETE_PROJECTS_FROM_LIST', sitesFormatted);
            const count = state.projectsList.length;
            commit('SET_PR_TOTAL', count);
            commit('SET_PR_LOADED', count);
            return Promise.resolve(sitesFormatted);
        },
        projectsListGetProject({state, dispatch}, id) {
            if (!id || typeof id !== 'number' && typeof id !== 'string') {
                throw 'Empty Id or type of id not Number|String';
            }
            if (!state.projectsList || state.projectsList.length === 0 || !id || typeof id !== 'number' && typeof id !== 'string') {
                return null;
            }
            return state.projectsList.find(el => el[typeof id === 'number' ? 'id' : 'site'] === id);
        },
        async projectsListLoadFirstOne({}) {
            const result = await projectsListGet({
                filter: '',
                sites_offset: 0,
                sites_limit: 1,
                scopes: PROJECTS_LIST_DEF_SCOPES,
                order: 'asc'
            }, projectsListApi);
            const tempList = projectsListResultFormatter(result, true);
            return Promise.resolve(tempList[0]);
        },
        async projectInfoLoad({}, {ids = true}) {
            const result = await projectInfoGet(ids);
            return Promise.resolve(result)
        },
        async projectArchivedLoad({}, {ids, domain, se, dateStart, dateEnd = true, mt = 1, fullseo = 0}) {
            const result = await projectArchivedGet({
                ids: ids,
                domain: domain,
                se: se,
                dateStart: dateStart,
                dateEnd: dateEnd,
                mt: mt,
                fullseo: fullseo
            });
            return Promise.resolve(result)
        },
        async projectsListGetByDomain({}, domain) {
            const result = await projectsListGetByDomain(domain, projectsListApi);
            const tempList = projectsListResultFormatter([result]);
            return Promise.resolve(tempList[0]);
        }
    },
    getters: {
        allProjFiltered(state) {
            return state.projectsList || [];
        },
        accProjects(state) {
            return state.projectsList.filter(item => (item.mt && item.mt === 1));
        },
        viewedProjects(state) {
            return state.projectsList.filter(item => (item.mt && item.mt === 2));
        }

    }
}
