import axios from 'axios'
import endpoints from '../../models/endpoints'

class CheckHttpApi {
    check (domain) {
        return axios.get(`${endpoints.api}/check-https/`, {
            params: {
                domain
            }
        })
    }
}

export default new CheckHttpApi()
